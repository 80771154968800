import { Icon } from "@iconify/react";

import useConfigFieldsQuery from "api/hooks/useConfigFieldsQuery";
import Drawer from "components/atoms/Drawer";
import Loader from "components/atoms/Loader";

import "./GeneralConfigurationDrawer.css";
import GeneralConfigurationForm from "./GeneralConfigurationForm/GeneralConfigurationForm";

interface GeneralConfigurationDrawerProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const GeneralConfigurationDrawer = ({
  open,
  setOpen,
}: GeneralConfigurationDrawerProps) => {
  const {
    data: configFields,
    isPending: configFieldsPending,
    isError: configFieldsError,
  } = useConfigFieldsQuery("configure_suspension");
  return (
    <Drawer open={open}>
      {open && (
        <div className="GeneralConfigurationDrawer__container">
          <div className="GeneralConfigurationDrawer__title">
            Configuración
            <button
              onClick={() => setOpen(false)}
              className="GeneralConfigurationDrawer__close_button"
            >
              <Icon icon="uil:times" />
            </button>
          </div>
          {configFieldsPending && (
            <div className="GeneralConfigurationDrawer__message_with_loader">
              Cargando las configuraciones
              <Loader />
            </div>
          )}
          {configFieldsError && (
            <div className="GeneralConfigurationDrawer__message_with_loader">
              <Icon icon="uil:exclamation-triangle" />
              Error al cargar las configuraciones, por favor inténtalo más
              tarde.
            </div>
          )}

          {configFields && (
            <GeneralConfigurationForm
              configFields={configFields}
              onClose={() => setOpen(false)}
              drawerOpen={open}
            />
          )}
        </div>
      )}
    </Drawer>
  );
};

export default GeneralConfigurationDrawer;
