import { useMutation } from "@tanstack/react-query";

import { issuesApi } from "feedback-api";

type ReportIssueVars = {
  issueType?: string;
  serviceName?: string;
  description: string;
  contactEmail?: string;
  blobsGetter: () => Promise<{
    mainContainerBlob: Blob | null;
    messagesContainerBlob: Blob | null;
  }>;
};

/**
 * Maximum size in bytes for the image files to attach.
 *
 * We allow sending up to 1000 KB in image binary data (500 KB per image), which
 * leaves 24 KB for HTTP headers and other form fields (assuming a 1 MB limit in
 * nginx). This should be more than sufficient— the typical size for the form is
 * around 300-400 bytes, thus leaving 23 KB just for headers.
 *
 * This limit is a last resort to avoid losing the form data submitted by the
 * user. We should do whatever we can to reduce the file size before calling
 * this mutation.
 */
const MAX_SIZE_BYTES = 500 * 1024;

const useReportIssueMutation = () => {
  return useMutation<string, unknown, ReportIssueVars>({
    mutationKey: ["issue"],
    mutationFn: async ({
      description,
      serviceName,
      issueType = "Bot se equivoca",
      contactEmail,
      blobsGetter,
    }) => {
      const { mainContainerBlob, messagesContainerBlob } = await blobsGetter();

      const chatFile =
        messagesContainerBlob && messagesContainerBlob.size <= MAX_SIZE_BYTES
          ? new File([messagesContainerBlob], "Captura del chat.jpg")
          : undefined;
      const screenFile =
        mainContainerBlob && mainContainerBlob.size <= MAX_SIZE_BYTES
          ? new File([mainContainerBlob], "Captura de la vista.jpg")
          : undefined;

      const { data } = await issuesApi.reportIssueV4IssuesPost(
        serviceName ?? "",
        issueType,
        window.location.href,
        description,
        contactEmail,
        chatFile,
        screenFile,
      );
      return data.ts;
    },
  });
};

export default useReportIssueMutation;
