import { Icon } from "@iconify/react";

import Button from "components/atoms/Button";
import Modal from "components/atoms/Modal";

import "./IncompleteAppointmentsModal.css";

interface IncompleteAppointmentsModalProps {
  isOpen?: boolean;
  selectedCandidatesCount: number;
  reassignmentsCount: number;
  onConfirm: () => void;
  onCancel: () => void;
}

const IncompleteAppointmentsModal = ({
  isOpen = false,
  selectedCandidatesCount,
  reassignmentsCount,
  onCancel,
  onConfirm,
}: IncompleteAppointmentsModalProps) => {
  const recibiran = "recibirá" + (reassignmentsCount === 1 ? "" : "n");
  return (
    <Modal isOpen={isOpen} onRequestClose={onCancel}>
      <div className="IncompleteAppointmentsModal">
        <h2 className="IncompleteAppointmentsModal__title">
          Algunas citas no están completas
        </h2>
        <p className="IncompleteAppointmentsModal__copy">
          Tienes pacientes <em>sin una nueva cita informada</em>. Si avanzas, se
          notificará a <em className="datum">{selectedCandidatesCount}</em>{" "}
          pacientes, pero solo <em className="datum">{reassignmentsCount}</em>{" "}
          {recibiran} información sobre su nueva cita.
        </p>
        <div className="IncompleteAppointmentsModal__data_container">
          <div className="IncompleteAppointmentsModal__data_element">
            <div className="IncompleteAppointmentsModal__data_element__header">
              <Icon icon="uil:users-alt" height="1rem" />
              Notificación de suspensión
            </div>
            <div className="IncompleteAppointmentsModal__data_element__value">
              {selectedCandidatesCount} pacientes
            </div>
          </div>
          <div className="IncompleteAppointmentsModal__data_element">
            <div className="IncompleteAppointmentsModal__data_element__header">
              <Icon icon="uil:calender" height="1rem" />
              Notificación de nueva cita
            </div>
            <div className="IncompleteAppointmentsModal__data_element__value">
              {reassignmentsCount} de {selectedCandidatesCount} pacientes
            </div>
          </div>
        </div>
        <div className="IncompleteAppointmentsModal__actions">
          <Button variant="outline" onClick={() => onCancel()}>
            Volver
          </Button>
          <Button onClick={() => onConfirm()}>Continuar</Button>
        </div>
      </div>
    </Modal>
  );
};

export default IncompleteAppointmentsModal;
