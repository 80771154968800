import { Icon } from "@iconify/react";
import { Dispatch, UnknownAction } from "@reduxjs/toolkit";
import { captureException } from "@sentry/react";
import { rankItem } from "@tanstack/match-sorter-utils";
import {
  ColumnFiltersState,
  FilterFn,
  Row,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import useCampaignsQuery from "api/hooks/useCampaignsQuery";
import Skeleton from "components/Skeleton";
import Callout from "components/atoms/Callout";
import Pill from "components/atoms/Pill";
import Table from "components/atoms/Table";
import { Campaign, CampaignStatus, campaignsApi } from "feedback-api";
import useAnalytics from "hooks/useAnalytics";
import { guardaIdEncuestaSeleccionada } from "store/slices/encuestas";
import { guardaIdEncuesta } from "store/slices/opciones";
import {
  agregaFiltro,
  guardaRangoFechas,
  limpiaFiltros,
  limpiaRespuestas,
} from "store/slices/respuestas";
import { addToast } from "store/slices/toasts";
import { humanizeShortDate } from "utils/date";

import "./CampaignList.css";

const columnHelper = createColumnHelper<Campaign>();

const minReportDate = new Date(Date.UTC(2024, 3, 10));

const handleDownloadReport = async (
  e: React.MouseEvent,
  campaign: Campaign,
  dispatch: Dispatch<UnknownAction>,
) => {
  e.stopPropagation();

  try {
    const { data } =
      await campaignsApi.getCampaignReportV4CampaignsCampaignIdReportGet(
        campaign.id,
        { responseType: "blob" },
      );
    const href = URL.createObjectURL(data as unknown as Blob);
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", `${campaign.name}.xlsx`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  } catch (err) {
    captureException(err);
    dispatch(
      addToast({
        message:
          "¡Ups! No se pudo descargar el reporte. Intenta de nuevo más tarde.",
        type: "error",
      }),
    );
  }
};

const getColumns = (dispatch: Dispatch<UnknownAction>) => [
  columnHelper.accessor("name", {
    header: "Nombre",
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  }),
  columnHelper.accessor("status", {
    header: "Estado",
    cell: (info) => {
      const value = info.getValue();
      const startDate = new Date(`${info.row.original.start_date}Z`);
      const [text, color] = getRenderForState(value, startDate);
      return <Pill variant={color}>{text}</Pill>;
    },
    footer: (props) => props.column.id,
  }),
  columnHelper.accessor("start_date", {
    header: "Fecha de inicio",
    cell: (info) => {
      const value = info.getValue();
      const date = new Date(`${value}Z`);
      return humanizeShortDate(date);
    },
    footer: (props) => props.column.id,
  }),
  columnHelper.accessor("loaded_rows", {
    header: "Filas válidas",
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  }),
  columnHelper.accessor("responded_rows", {
    header: "Respuestas",
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  }),
  columnHelper.accessor("created_at", {
    header: "Fecha de creación",
    cell: (info) => {
      const value = info.getValue();
      const date = new Date(`${value}Z`);
      return (
        <>
          <a
            target="_blank"
            className={classNames("CampaignList__download_button", {
              "CampaignList__download_button--disabled":
                downloadReportButtonDisabled(
                  minReportDate,
                  date,
                  info.row.original.status,
                ),
            })}
            onClick={(e) =>
              handleDownloadReport(e, info.row.original, dispatch)
            }
          >
            <Icon
              className="CampaignList__download_icon"
              icon="uil:file-download"
            />
            Descargar reporte
          </a>
          <span
            className={classNames("CampaignList__created_at", {
              "CampaignList__created_at--disabled":
                downloadReportButtonDisabled(
                  minReportDate,
                  date,
                  info.row.original.status,
                ),
            })}
          >
            {date.toLocaleDateString("en-GB")}
          </span>
        </>
      );
    },
    footer: (props) => props.column.id,
  }),
];

const getRenderForState = (value: CampaignStatus, startDate: Date) => {
  if (value === CampaignStatus.Processed && startDate > new Date()) {
    return ["Programada", "gray"] as const;
  }
  return {
    [CampaignStatus.Failed]: ["Fallida", "red"] as const,
    [CampaignStatus.Processed]: ["Enviada", "green"] as const,
    [CampaignStatus.Processing]: ["Procesando", "blue"] as const,
  }[value];
};

const downloadReportButtonDisabled = (
  minReportDate: Date,
  date: Date,
  campaignStatus: CampaignStatus,
) => {
  return minReportDate > date || campaignStatus !== "PROCESSED";
};
const fuzzyFilter: FilterFn<unknown> = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);
  addMeta({
    itemRank,
  });
  return itemRank.passed;
};

const CampaignList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const track = useAnalytics();

  const { data: campaigns, isLoading, isError } = useCampaignsQuery();
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const table = useReactTable({
    data: campaigns ?? [],
    columns: getColumns(dispatch),
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    state: {
      columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
  });
  const { rows } = table.getRowModel();

  const routeToCampaign = async (campaign: Campaign) => {
    const idEncuesta = campaign.poll_id;
    if (campaign.status !== "PROCESSED") {
      return;
    }
    track("Campaigns", "row", "verRespuestas", { campaignId: campaign.id });
    dispatch(limpiaRespuestas());
    dispatch(limpiaFiltros());
    dispatch(guardaIdEncuesta(idEncuesta));
    dispatch(guardaIdEncuestaSeleccionada(idEncuesta));
    dispatch(
      agregaFiltro({
        busqueda: campaign.name,
        nombreHeader: "_campana",
        textoHeader: "Campaña",
        idEncuesta: Number(idEncuesta),
      }),
    );

    const date = Date.parse(`${campaign.start_date}Z`);
    dispatch(guardaRangoFechas([date, date]));
    navigate("/respuestas");
  };

  return (
    <div className="CampaignList">
      {isError ? (
        <Callout>Algo salió mal. Intenta de nuevo más tarde.</Callout>
      ) : (
        <Table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {isLoading
              ? Array.from({ length: 10 }).map((_, i) => (
                  <tr key={i}>
                    {Array.from({ length: getColumns(dispatch).length }).map(
                      (_, j) => (
                        <td key={j}>
                          <Skeleton />
                        </td>
                      ),
                    )}
                  </tr>
                ))
              : rows.map((row: Row<Campaign>) => {
                  return (
                    <tr
                      key={row.id}
                      onClick={() => routeToCampaign(row.original)}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </td>
                      ))}
                    </tr>
                  );
                })}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default CampaignList;
