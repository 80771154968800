import { useQuery } from "@tanstack/react-query";
import { formatISO } from "date-fns";

import { DefaultValue, MessagePreview, suspensionsApi } from "feedback-api";

const useSuspensionMessagePreviews = (
  appointment: object | null,
  customExternalMeta?: Record<
    string,
    string | boolean | undefined | DefaultValue
  >,
  reassignmentDatetime?: Date,
) => {
  return useQuery<MessagePreview[], unknown>({
    enabled: appointment !== null,
    queryKey: ["campaignPreview"],
    queryFn: async ({ signal }) => {
      const {
        data: { previews },
      } = await suspensionsApi.previewMessagesV4SuspensionsPreviewsPost(
        {
          appointment: appointment!,
          custom_external_meta: customExternalMeta as
            | Record<string, string | boolean>
            | undefined,
          reassignment_datetime: reassignmentDatetime
            ? formatISO(reassignmentDatetime)
            : undefined,
        },
        { signal },
      );
      return previews;
    },
  });
};

export default useSuspensionMessagePreviews;
