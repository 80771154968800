import { Icon } from "@iconify/react";
import { HttpStatusCode, isAxiosError } from "axios";
import { useState } from "react";
import { Link } from "react-router";

import useSuspensionsQuery from "api/hooks/useSuspensionsQuery";
import Button from "components/atoms/Button";
import EmptyStateCard from "components/organisms/EmptyStateCard/EmptyStateCard";
import Error403 from "components/pages/Error403";

import GeneralConfigurationDrawer from "./GeneralConfigurationDrawer/GeneralConfigurationDrawer";
import "./Suspensions.css";
import SuspensionsTable from "./SuspensionsTable";

const NewSuspensionLink = () => (
  <Link className="Suspensions__create" to="nueva">
    Crear nueva suspensión
  </Link>
);

const Suspensions = () => {
  const { data, error, isLoading } = useSuspensionsQuery();
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  if (
    isAxiosError(error) &&
    error.response?.status === HttpStatusCode.Forbidden
  ) {
    return <Error403 mensaje="No puedes ver esta página." />;
  }

  return (
    <>
      <div className="Suspensions">
        <div className="Suspensions__header">
          <h2 className="Suspensions__title">Suspensión de citas</h2>
          <div className="Suspensions__options">
            <Button
              variant="outline"
              onClick={() => setDrawerOpen(true)}
              disabled={isLoading}
              icon={<Icon icon="uil:cog" />}
            >
              Configuración
            </Button>
            <NewSuspensionLink />
          </div>
        </div>
        {data?.length === 0 ? (
          <EmptyStateCard
            preview={
              <video
                className="Suspensions__preview"
                autoPlay
                loop
                muted
                playsInline
              >
                <source
                  src="videos/suspensions/conversation-empty-state.webm"
                  type="video/webm"
                />
                <source
                  src="videos/suspensions/conversation-empty-state.mp4"
                  type="video/mp4"
                />
              </video>
            }
            title="¡Comienza a crear notificaciones de suspensión!"
            description="Una vez que hayas creado suspensiones, podrás visualizarlas y gestionarlas directamente desde esta sección."
            action={<NewSuspensionLink />}
          />
        ) : (
          <div className="Suspensions__wrapper">
            <div className="Suspensions__content">
              <h3 className="Suspensions__table_title">
                Historial de suspensiones
              </h3>
              <SuspensionsTable suspensions={data} />
            </div>
          </div>
        )}
      </div>
      <GeneralConfigurationDrawer open={isDrawerOpen} setOpen={setDrawerOpen} />
    </>
  );
};

export default Suspensions;
