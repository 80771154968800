import { formatISO } from "date-fns";
import {
  useState,
  useRef,
  useMemo,
  useReducer,
  Reducer,
  useEffect,
} from "react";

import useGetSuspensionCandidatesQuery from "api/hooks/useGetSuspensionCandidatesQuery";
import useGetSuspensionSettingsQuery from "api/hooks/useGetSuspensionSettingsQuery";
import Stepper from "components/molecules/Stepper";

import "../../Waitlists/NewCampaign/NewCampaign.css";
import "../../Waitlists/NewCampaign/UploadSpreadsheetStep/UploadSpreadsheetStep.css";
import CompletedSuspensionStep from "./CompletedSuspensionStep";
import ConfigureSuspensionMessagesStep from "./ConfigureSuspensionMessagesStep";
import ConfigureSuspensionStep from "./ConfigureSuspensionStep";
import "./NewSuspension.css";
import {
  NewSuspensionContext,
  NewSuspensionDispatchContext,
} from "./NewSuspensionContext";
import {
  createNewSuspensionContextState,
  type NewSuspensionContextState,
} from "./NewSuspensionContextState";
import { newSuspensionReducer } from "./NewSuspensionReducer";
import type { NewSuspensionAction } from "./NewSuspensionReducer";
import VerifySuspensionStep from "./VerifySuspensionStep";

export type ProfessionalOption = {
  label: string;
  value: string;
  centerId: string | null;
};

const CONFIGURE_SUSPENSION_STEP = 1;
const VERIFY_STEP = 2;
const CONFIGURE_SUSPENSION_MESSAGES_STEP = 3;
const COMPLETED_STEP = 4;
const STEPS = [
  "Configurar suspensión",
  "Verificar pacientes",
  "Configurar mensajes",
];

type NewSuspensionStep =
  | typeof CONFIGURE_SUSPENSION_STEP
  | typeof VERIFY_STEP
  | typeof CONFIGURE_SUSPENSION_MESSAGES_STEP
  | typeof COMPLETED_STEP;

const NewSuspension = () => {
  const [newSuspension, dispatch] = useReducer<
    Reducer<NewSuspensionContextState, NewSuspensionAction>
  >(newSuspensionReducer, createNewSuspensionContextState());
  const ref = useRef<HTMLDivElement>(null);
  const { selectedProfessional, startDate, endDate } = newSuspension;

  const {
    data: suspensionCandidates,
    error: suspensionCandidatesError,
    isFetching: isSuspensionCandidatesFetching,
  } = useGetSuspensionCandidatesQuery({
    professionalId: selectedProfessional?.value,
    centerId: selectedProfessional?.centerId,
    startDate: formatISO(startDate),
    endDate: formatISO(endDate),
    professionalName: selectedProfessional?.label,
  });

  const setStep = (step: number) => {
    ref.current?.parentElement?.scrollTo({ top: 0 });
    if (enabledSteps.includes(step as NewSuspensionStep)) {
      setCurrentStep(step as NewSuspensionStep);
    }
  };
  const [currentStep, setCurrentStep] = useState<NewSuspensionStep>(
    CONFIGURE_SUSPENSION_STEP,
  );

  const enabledSteps = useMemo(() => {
    switch (currentStep) {
      case CONFIGURE_SUSPENSION_STEP:
        return [CONFIGURE_SUSPENSION_STEP];

      case VERIFY_STEP:
        return [CONFIGURE_SUSPENSION_STEP, VERIFY_STEP];

      default:
        return [
          CONFIGURE_SUSPENSION_STEP,
          VERIFY_STEP,
          CONFIGURE_SUSPENSION_MESSAGES_STEP,
        ];
    }
  }, [currentStep]);

  const resetForm = () => {
    setCurrentStep(CONFIGURE_SUSPENSION_STEP);
  };

  const { data: suspensionSettings, isPending: isSuspensionSettingsPending } =
    useGetSuspensionSettingsQuery();

  useEffect(() => {
    if (isSuspensionSettingsPending) {
      return;
    }
    dispatch({
      type: "SET_SUSPENSION_SETTING_TYPE",
      payload: suspensionSettings?.[0].type ?? "BASIC",
    });
  }, [isSuspensionSettingsPending, suspensionSettings]);

  return (
    <NewSuspensionContext.Provider value={newSuspension}>
      <NewSuspensionDispatchContext.Provider value={dispatch}>
        <div className="NewSuspension">
          {currentStep === COMPLETED_STEP ? (
            <CompletedSuspensionStep resetForm={resetForm} />
          ) : (
            <div className="NewCampaign" ref={ref}>
              <h2 className="NewCampaign__title">Suspensión de citas</h2>
              <div className="NewCampaign__content">
                <Stepper
                  currentStep={currentStep}
                  enabledSteps={enabledSteps}
                  onClick={setStep}
                  steps={STEPS}
                />
                {currentStep === CONFIGURE_SUSPENSION_STEP && (
                  <ConfigureSuspensionStep
                    onSubmit={() => setCurrentStep(VERIFY_STEP)}
                  />
                )}
                {currentStep === VERIFY_STEP &&
                  !!newSuspension.selectedProfessional && (
                    <VerifySuspensionStep
                      suspensionCandidates={suspensionCandidates}
                      error={suspensionCandidatesError}
                      isFetching={isSuspensionCandidatesFetching}
                      onSuccess={() =>
                        setCurrentStep(CONFIGURE_SUSPENSION_MESSAGES_STEP)
                      }
                    />
                  )}
                {currentStep === CONFIGURE_SUSPENSION_MESSAGES_STEP && (
                  <ConfigureSuspensionMessagesStep
                    onSuccess={() => setCurrentStep(COMPLETED_STEP)}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </NewSuspensionDispatchContext.Provider>
    </NewSuspensionContext.Provider>
  );
};

export default NewSuspension;
