import { Icon } from "@iconify/react";
import classNames from "classnames";
import { forwardRef, useId } from "react";
import { GroupBase, SelectInstance } from "react-select";

import Select from "components/atoms/Select";
import { SelectProps } from "components/atoms/Select/Select";

import "./SelectGroup.css";

// Redeclare forwardRef types as generic
// See https://oida.dev/typescript-react-generic-forward-refs/ Option 3
declare module "react" {
  function forwardRef<T, P = unknown>(
    render: (props: P, ref: React.Ref<T>) => React.ReactNode | null,
  ): (props: P & React.RefAttributes<T>) => React.ReactNode | null;
}

type SelectGroupProps<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = SelectProps<Option, IsMulti, Group> & {
  onFocus?: () => void;
  label: string;
  description?: string;
  error?: string;
  collapsable?: boolean;
};

const SelectGroup = forwardRef(function SelectGroup<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  {
    label,
    description,
    error,
    value,
    options,
    onFocus,
    ...selectProps
  }: SelectGroupProps<Option, IsMulti, Group>,
  ref: React.ForwardedRef<SelectInstance<Option, IsMulti, Group>>,
) {
  const htmlId = useId();

  return (
    <div
      className={classNames("SelectGroup", {
        error: !!error,
      })}
    >
      <div className="SelectGroup__context">
        <label className="SelectGroup__label" htmlFor={htmlId}>
          {label}
        </label>
        {description && (
          <p className="SelectGroup__description">{description}</p>
        )}
      </div>

      <div className="SelectGroup__dynamic" tabIndex={-1}>
        <Select
          id={htmlId}
          value={value}
          onFocus={onFocus}
          {...selectProps}
          ref={ref}
          options={options}
        />
        <div className="SelectGroup__error">
          <Icon
            className="SelectGroup__error_icon"
            icon="uil:exclamation-triangle"
          />
          <p className="SelectGroup__error_message">{error}</p>
        </div>
      </div>
    </div>
  );
});

export default SelectGroup;
