import { InlineIcon } from "@iconify/react";
import {
  differenceInDays,
  differenceInHours,
  parseISO,
  formatDistanceToNow,
} from "date-fns";
import { es } from "date-fns/locale";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { loginSelector } from "store/slices/login";

import "./AlertaPilotos.css";

const daysToPilotEndForNotification = 5;
const usuariosPiloto: { nombre: string; exp: string }[] = [];

const AlertaPilotos = () => {
  const { clientName } = useSelector(loginSelector);
  const usuario = usuariosPiloto.find((u) => u.nombre === clientName);
  const [diferencia, setDiferencia] = useState<number>();
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const f = () =>
      setDiferencia(
        usuario && differenceInDays(parseISO(usuario.exp), Date.now()),
      );
    f();
    const interval = setInterval(f, 60_000);
    return () => clearInterval(interval);
  }, [usuario]);

  if (
    usuario === undefined ||
    !visible ||
    diferencia === undefined ||
    diferencia > daysToPilotEndForNotification
  ) {
    return null;
  }

  const correoPrincipal = `ventas@cero.ai`;
  const cc = `contacto@cero.ai`;
  const asunto = `Contacto%20Piloto%20${clientName}`;
  const cuerpo = `Tu%20mensaje`;
  const dias = formatDistanceToNow(parseISO(usuario.exp), {
    locale: es,
    addSuffix: true,
  });
  const verbo =
    differenceInHours(parseISO(usuario.exp), Date.now()) > 0
      ? "finaliza"
      : "finalizó";

  return (
    <div className="AlertaPilotos">
      <strong>{dias}</strong> {verbo} tu piloto del servicio. ¿Quieres continuar
      confirmando con Cero? 👉{" "}
      <a
        className="AlertaPilotos__link"
        href={`mailto:${correoPrincipal}?cc=${cc}&subject=${asunto}&body=${cuerpo}`}
      >
        Contáctanos
      </a>
      <button
        className="AlertaPilotos__close"
        onClick={() => setVisible(false)}
      >
        <InlineIcon icon="mdi:close" />
      </button>
    </div>
  );
};

export default AlertaPilotos;
