import { Icon } from "@iconify/react";
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Row,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import { SuspensionRowData } from "api/hooks/useSuspensionsQuery";
import Skeleton from "components/Skeleton";
import Table from "components/atoms/Table";
import PaginationControls from "components/molecules/PaginationControls";
import useAnalytics from "hooks/useAnalytics";
import { guardaIdEncuestaSeleccionada } from "store/slices/encuestas";
import { guardaIdEncuesta } from "store/slices/opciones";
import {
  agregaFiltro,
  guardaRangoFechas,
  limpiaFiltros,
  limpiaRespuestas,
} from "store/slices/respuestas";

import "./SuspensionsTable.css";
import { fuzzyFilter, getColumns } from "./helpers";

interface SuspensionsTableProps {
  suspensions: SuspensionRowData[] | undefined;
}

const SuspensionsTable = ({ suspensions }: SuspensionsTableProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const track = useAnalytics();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const table = useReactTable({
    data: suspensions ?? [],
    columns: getColumns(),
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: { pagination },
  });

  const navigateToSuspensionAnswers = async (row: SuspensionRowData) => {
    if (!row.professionalName) {
      return;
    }
    const serviceId = row.pollId;
    track("SuspensionsTable", "row", "verRespuestas", {
      professionalName: row.professionalName,
    });
    dispatch(limpiaRespuestas());
    dispatch(limpiaFiltros());
    dispatch(guardaIdEncuesta(serviceId));
    dispatch(guardaIdEncuestaSeleccionada(serviceId));
    dispatch(
      agregaFiltro({
        busqueda: `Suspensión para ${row.professionalName}`,
        nombreHeader: "suspension_name",
        textoHeader: "Nombre de la suspensión",
        idEncuesta: Number(serviceId),
      }),
    );

    const date = Date.parse(`${row.createdAt}Z`);
    dispatch(guardaRangoFechas([date, date]));
    navigate("/respuestas");
  };

  const { rows } = table.getRowModel();

  if (!suspensions) {
    return (
      <div className="SuspensionsTable">
        <Table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {Array.from({ length: pagination.pageSize }).map((_, i) => (
              <tr key={i}>
                {Array.from({ length: getColumns().length }).map((_, j) => (
                  <td key={j}>
                    <Skeleton />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th colSpan={getColumns().length}>
                <div className="SuspensionsTable__pagination">
                  <Skeleton />
                  <Skeleton />
                </div>
              </th>
            </tr>
          </tfoot>
        </Table>
      </div>
    );
  }
  return (
    <div className="SuspensionsTable">
      <Table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  className={classNames({
                    SuspensionsTable__sortable: header.column.getCanSort(),
                  })}
                >
                  <div className="SuspensionsTable__header_wrapper">
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                    {header.column.getIsSorted() === "asc" && (
                      <Icon
                        className="SuspensionsTable__sort_icon"
                        icon="uil:arrow-up"
                      />
                    )}
                    {header.column.getIsSorted() === "desc" && (
                      <Icon
                        className="SuspensionsTable__sort_icon"
                        icon="uil:arrow-down"
                      />
                    )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {rows.map((row: Row<SuspensionRowData>) => {
            return (
              <tr
                className="SuspensionsTable__body_row"
                key={row.id}
                tabIndex={0}
                onClick={() => navigateToSuspensionAnswers(row.original)}
                onKeyDown={(e) =>
                  ["Enter", " "].includes(e.key) &&
                  navigateToSuspensionAnswers(row.original)
                }
              >
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <th colSpan={getColumns().length}>
              <div className="SuspensionsTable__pagination">
                <span>
                  Mostrando{" "}
                  <strong>
                    {pagination.pageIndex * pagination.pageSize + 1}&ndash;
                    {Math.min(
                      (pagination.pageIndex + 1) * pagination.pageSize,
                      suspensions.length,
                    )}
                  </strong>{" "}
                  de <strong>{suspensions.length}</strong>
                </span>
                <PaginationControls
                  pages={
                    1 +
                    Math.trunc((suspensions.length - 1) / pagination.pageSize)
                  }
                  current={pagination.pageIndex + 1}
                  setCurrent={(newIndex) =>
                    setPagination({ ...pagination, pageIndex: newIndex - 1 })
                  }
                />
              </div>
            </th>
          </tr>
        </tfoot>
      </Table>
    </div>
  );
};

export default SuspensionsTable;
