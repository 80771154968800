import { Icon } from "@iconify/react";
import Particles from "@tsparticles/react";
import { useContext, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import Button from "components/atoms/Button";
import useAnalytics from "hooks/useAnalytics";
import { guardaIdEncuestaSeleccionada } from "store/slices/encuestas";
import { guardaIdEncuesta } from "store/slices/opciones";
import {
  agregaFiltro,
  guardaRangoFechas,
  limpiaFiltros,
  limpiaRespuestas,
} from "store/slices/respuestas";

import { NewSuspensionContext } from "../NewSuspensionContext";
import { NewSuspensionDispatchContext } from "../NewSuspensionContext";
import "./CompletedSuspensionStep.css";

interface CompletedSuspensionStepProps {
  resetForm: () => void;
}

const CompletedSuspensionStep = ({
  resetForm,
}: CompletedSuspensionStepProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const track = useAnalytics();
  const { selectedProfessional, selectedAppointments, serviceId } =
    useContext(NewSuspensionContext);
  const newSuspensionDispatch = useContext(NewSuspensionDispatchContext);
  const handleNewSuspension = () => {
    newSuspensionDispatch({ type: "RESET" });
    resetForm();
  };
  const patientCount = useMemo(
    () =>
      `${selectedAppointments?.length} paciente${selectedAppointments?.length === 1 ? "" : "s"}`,
    [selectedAppointments],
  );

  const navigateToSuspensionAnswers = async () => {
    if (!selectedProfessional?.label || !serviceId) {
      return;
    }
    track("SuspensionsTable", "row", "verRespuestas", {
      professionalName: selectedProfessional.label,
    });
    dispatch(limpiaRespuestas());
    dispatch(limpiaFiltros());
    dispatch(guardaIdEncuesta(serviceId));
    dispatch(guardaIdEncuestaSeleccionada(serviceId));
    dispatch(
      agregaFiltro({
        busqueda: `Suspensión para ${selectedProfessional.label}`,
        nombreHeader: "suspension_name",
        textoHeader: "Nombre de la suspensión",
        idEncuesta: Number(serviceId),
      }),
    );

    const date = Date.now();
    dispatch(guardaRangoFechas([date, date]));
    navigate("/respuestas");
  };

  return (
    <div className="CompletedSuspension__container">
      <Particles id="confetti" options={{ preset: "confetti" }} />
      <div className="CompletedSuspension__header_icon_background">
        <Icon
          icon="uil:comment-verify"
          className="CompletedSuspension__header_icon"
        />
      </div>
      <div className="CompletedSuspension__data">
        <div className="CompletedSuspension__data_text">
          <p className="CompletedSuspension__data_title">
            ¡El envío de mensajes se ha iniciado con éxito!
          </p>
          <p className="CompletedSuspension__data_subtitle">
            Se está notificando a <strong>{patientCount}</strong> sobre la
            suspensión de sus citas con{" "}
            <strong>{selectedProfessional?.label}</strong>
          </p>
        </div>
        <div className="CompletedSuspension__data_buttons">
          <Button onClick={handleNewSuspension}>
            Crear nueva notificación
          </Button>
          <Button variant="outline" onClick={navigateToSuspensionAnswers}>
            Ver respuestas
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CompletedSuspensionStep;
