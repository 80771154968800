import classNames from "classnames";
import { Link } from "react-router";

import useCampaignLimitsQuery from "api/hooks/useCampaignLimitsQuery";
import SmartphoneMessage from "components/Feedback/InteractionDrawer/Smartphone/SmartphoneMessage";
import Callout from "components/atoms/Callout";
import EmptyStateCard from "components/organisms/EmptyStateCard/EmptyStateCard";
import useAnalytics from "hooks/useAnalytics";

import CampaignLimits from "./CampaignLimits";
import CampaignList from "./CampaignList";
import "./Campaigns.css";

const Campaigns = () => {
  const track = useAnalytics();

  const { data: campaignLimits, isError } = useCampaignLimitsQuery();

  const createCampaignButton = (section: string) => (
    <Link
      className={classNames("Campaigns__create-button", {
        "Campaigns__create-button--disabled":
          !campaignLimits ||
          campaignLimits.max_lifetime_interactions <=
            campaignLimits.lifetime_interactions ||
          campaignLimits.max_monthly_interactions <=
            campaignLimits.monthly_interactions,
      })}
      to="campanyas/nueva"
      onClick={() => track("Campaigns", section, "verNuevaCampanya")}
    >
      Nueva Campaña
    </Link>
  );

  return (
    <div className="Campaigns">
      {isError ? (
        <Callout>Algo salió mal. Intenta de nuevo más tarde.</Callout>
      ) : campaignLimits?.lifetime_interactions === 0 ? (
        <EmptyStateCard
          title="¡Crea tu primera campaña!"
          description="Una vez que hayas creado campañas, podrás visualizarlas y gestionarlas directamente desde esta sección."
          action={createCampaignButton("cta")}
          preview={
            <div className="Campaigns__sample_figure">
              <SmartphoneMessage
                data={{
                  message: {
                    timestamp: new Date(2024, 0, 1, 9, 0),
                    content:
                      "Hola, le escribo del Hospital de Cero. Necesitamos saber si la paciente Valentina Antonia Reyes González que figura en nuestra lista de espera, todavía necesita el procedimiento/examen de Gastroenterología. ¿Me confirma si aún requiere la cita?",
                    sender: "USER",
                    type: "TEXTO",
                    id: 0,
                  },
                  current: true,
                  date: new Date(2024, 0, 1, 9, 0),
                }}
              />
              <SmartphoneMessage
                data={{
                  message: {
                    timestamp: new Date(2024, 0, 1, 13, 37),
                    content: "Sí, muchas gracias. 😊",
                    sender: "BOT",
                    type: "TEXTO",
                    id: 1,
                  },
                  current: true,
                  date: new Date(2024, 0, 1, 13, 37),
                }}
              />
            </div>
          }
        />
      ) : (
        <>
          <div className="Campaigns__header">
            <h2 className="Campaigns__title">Campañas</h2>
            {createCampaignButton("header")}
          </div>
          <div className="Campaigns__body">
            <CampaignList />
            <CampaignLimits />
          </div>
        </>
      )}
    </div>
  );
};

export default Campaigns;
