import { Icon } from "@iconify/react";

import { humanizeFullDate } from "utils/date";

import "./ConfigurationCard.css";

interface ConfigurationCardProps {
  startDate: Date;
  endDate: Date;
  professionalName: string;
}

const ConfigurationCard = ({
  startDate,
  endDate,
  professionalName,
}: ConfigurationCardProps) => {
  return (
    <div className="ConfigurationCard">
      <div className="ConfigurationCard__section">
        <div className="ConfigurationCard__header">
          <Icon icon="uil:user-square" />
          <span>Profesional</span>
        </div>
        <span className="ConfigurationCard__professional">
          {professionalName}
        </span>
      </div>
      <div className="ConfigurationCard__section">
        <div className="ConfigurationCard__header">
          <Icon icon="uil:calendar-alt" />
          <span>Fecha de inicio</span>
        </div>
        <span>{humanizeFullDate(startDate)}</span>
      </div>
      <div className="ConfigurationCard__section">
        <div className="ConfigurationCard__header">
          <Icon icon="uil:calendar-alt" />
          <span>Fecha de término</span>
        </div>
        <span>{humanizeFullDate(endDate)}</span>
      </div>
    </div>
  );
};

export default ConfigurationCard;
