import { InlineIcon } from "@iconify/react";
import { NavLink, Outlet } from "react-router";

import logoCero from "assets/images/logo.png";

import "./Tutoriales.css";
import { videos } from "./helpers";

const Tutoriales = () => {
  return (
    <div className="Tutoriales">
      <h1 className="Tutoriales__titulo">Tutoriales</h1>
      <div className="Tutoriales__contenedor">
        <div className="Tutoriales__video">
          <Outlet />
        </div>
        <div>
          <div className="Tutoriales__titulo_playlist">
            <p>Tutoriales de Feedback</p>
            <p className="Tutoriales__conteo_playlist">
              {Object.keys(videos).length} videos
            </p>
          </div>
          <div className="Tutoriales__botones">
            {Object.entries(videos).map(
              ([videoId, { titulo, duracion, thumbnail }], i) => (
                <NavLink
                  to={videoId}
                  key={`boton-video-${i}`}
                  className={({ isActive }) =>
                    "Tutoriales__boton" +
                    (isActive ? " Tutoriales__boton--activo" : "")
                  }
                >
                  <p className="Tutoriales__miniatura_numero">
                    <InlineIcon
                      className="Tutoriales__miniatura_play"
                      icon="mdi:play"
                    />{" "}
                    <span className="Tutoriales__miniatura_n">{i + 1}</span>
                  </p>
                  <div
                    className="Tutoriales__miniatura"
                    style={{ backgroundImage: `url(${thumbnail})` }}
                  >
                    <p className="Tutoriales__miniatura_duracion">{duracion}</p>
                  </div>
                  <p className="Tutoriales__titulo_miniatura">{titulo}</p>
                  <p className="Tutoriales__autor">Jorge Pérez</p>
                </NavLink>
              ),
            )}
            <img
              className="Tutoriales__bajada_relleno"
              src={logoCero}
              alt="Logo de Cero"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tutoriales;
