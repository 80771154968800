import classNames from "classnames";
import { forwardRef } from "react";

import "./Input.css";

interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  onChange?: (value?: string | number | readonly string[]) => void;
}

const Input = forwardRef(function Input(
  { className, onChange, ...props }: InputProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  return (
    <input
      className={classNames("Input", className)}
      onChange={(e) => onChange?.(e.target.value)}
      ref={ref}
      {...props}
    />
  );
});

export default Input;
