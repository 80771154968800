import { Icon } from "@iconify/react";
import classNames from "classnames";
import { ReactNode } from "react";

import "./Callout.css";

interface CalloutProps {
  variant?: "success" | "danger";
  children?: ReactNode;
}

const Callout = ({ variant, children }: CalloutProps) => {
  return (
    <div
      className={classNames("Callout", {
        "Callout--success": variant === "success",
        "Callout--danger": variant === "danger",
      })}
    >
      <Icon
        className="Callout__icon"
        icon={classNames({
          "uil:check-circle": variant === "success",
          "uil:exclamation-triangle": variant === "danger",
        })}
      />
      {children}
    </div>
  );
};

export default Callout;
