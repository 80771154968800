import { InlineIcon } from "@iconify/react";
import { parseISO } from "date-fns";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";

import { eliminarReaccion } from "api/endpoints";
import { Reaction } from "api/types/responses";
import InteractionNoteIcon from "components/Feedback/InteractionDrawer/InteractionNotes/InteractionNote/InteractionNoteIcon";
import { Emoji } from "components/Feedback/InteractionDrawer/InteractionNotes/InteractionNote/InteractionNoteIcon/emojis";
import { formatearFecha } from "helpers/respuestas";
import useAnalytics from "hooks/useAnalytics";
import { eliminaReaccionDeRespuesta } from "store/slices/respuestas";

import "./FilaNota.css";

interface FilaNotaProps {
  reaccion: Reaction;
  refrescar: () => void;
}

const FilaNota = ({ reaccion, refrescar }: FilaNotaProps) => {
  const { serviceId, patientId } = useParams<{
    serviceId: string;
    patientId: string;
  }>();
  const [eliminando, setEliminando] = useState(false);
  const dispatch = useDispatch();
  const track = useAnalytics();

  if (!serviceId || !patientId) {
    return <div className="FilaNota" />;
  }

  const clickEnBorrar = () => {
    setEliminando(true);
    eliminarReaccion(serviceId, patientId, reaccion.id)
      .then(() => {
        track("Feedback", "Chat", "eliminarNota", {
          idEncuesta: serviceId,
          idUsuario: patientId,
          idNota: reaccion.id,
        });
        dispatch(
          eliminaReaccionDeRespuesta({
            idUsuario: patientId,
            fecha: reaccion.created_at,
          }),
        );
        refrescar();
      })
      .catch(() => setEliminando(false));
  };

  const fechaAgregadaLegible = formatearFecha(
    parseISO(reaccion.created_at),
    true,
  );

  return (
    <div className="FilaNota">
      <div className="FilaNota__emoji_nota">
        <InteractionNoteIcon emoji={reaccion.reaction_emoji as Emoji} />
      </div>
      <div className="FilaNota__texto_nota">
        {reaccion.reaction_text || (
          <p className="FilaNota__texto_nota_sin_texto">Sin texto</p>
        )}
      </div>
      <div className="FilaNota__fecha_nota">{fechaAgregadaLegible}</div>
      <div className="FilaNota__acciones">
        <button
          className="FilaNota__boton_eliminar"
          title="Eliminar esta nota"
          onClick={clickEnBorrar}
          disabled={eliminando}
        >
          <InlineIcon icon="mdi:delete" />
        </button>
      </div>
    </div>
  );
};

export default FilaNota;
