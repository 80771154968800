import { Icon } from "@iconify/react";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMatch, useNavigate } from "react-router";

import useBranchesQuery from "api/hooks/useBranchesQuery";
import useChangeAlertStatusMutation from "api/hooks/useChangeAlertStatusMutation";
import { Alert } from "api/types/domain";
import {
  getAlertButtonIcon,
  getAlertButtonLabel,
  getAlertButtonTitle,
} from "components/Feedback/Alerts/helpers";
import { showAlertDismissedBy } from "helpers/permisos";
import useAnalytics from "hooks/useAnalytics";
import { loginSelector } from "store/slices/login";

import "./AlertElement.css";

interface AlertElementProps {
  alert: Alert;
  highlighted: boolean;
}

const AlertElement = ({ alert, highlighted }: AlertElementProps) => {
  const getDrawerRight = () =>
    document.querySelector(".InteractionDrawer")?.getBoundingClientRect()
      .width || 0;

  const { mutate, isPending } = useChangeAlertStatusMutation({
    alertId: alert.id,
    serviceId: alert.serviceId,
    patientId: alert.patientId,
  });
  const [buttonRightPosition, setButtonRightPosition] =
    useState(getDrawerRight());
  const navigate = useNavigate();
  const { data: branches } = useBranchesQuery();
  const match = useMatch("alertas/:serviceId/:patientId");
  const { cuenta } = useSelector(loginSelector);
  const track = useAnalytics();

  const changeAlertState = () => {
    track(
      "Feedback",
      "Alerts",
      alert.solved ? "markAlertAsPending" : "markAlertAsSolved",
      { alert },
    );
    mutate({ solved: !alert.solved });
  };

  const openAlertChat = () => {
    if (
      (match?.params && Number(match.params.patientId)) !== alert.patientId ||
      (match?.params && Number(match.params.serviceId)) !== alert.serviceId
    ) {
      navigate(`/alertas/${alert.serviceId}/${alert.patientId}`);
      track("Feedback", "Alerts", "alertElementClick", {
        alert,
      });
    }
  };

  useEffect(() => {
    const updateButtonPositionInterval = setInterval(
      () => setButtonRightPosition(getDrawerRight()),
      50,
    );
    return () => clearInterval(updateButtonPositionInterval);
  }, []);

  const { patientName, serviceName, branchName } = alert;
  const alertData =
    `${patientName} • ${serviceName}` +
    (branches && branches.length > 1 ? ` • ${branchName}` : "");

  return (
    <button
      className={classNames({
        AlertElement: true,
        "AlertElement--pending": !alert.solved,
        "AlertElement--solved": alert.solved,
        "AlertElement--selected": highlighted,
      })}
      onClick={openAlertChat}
      key={alert.id}
    >
      <span className="AlertElement__icon_container">
        <Icon
          className="AlertElement__icon"
          icon={alert.solved ? "mdi:bell-check" : "mdi:bell-ring"}
        />
        {showAlertDismissedBy(cuenta) && alert.solved && (
          <span className="AlertElement__solvedBy">{alert.solvedBy}</span>
        )}
      </span>
      <span className="AlertElement__time">{alert.formattedTimestamp}</span>
      <span className="AlertElement__name">{alert.typeName}</span>
      <span className="AlertElement__data">{alertData}</span>
      {highlighted && (
        <button
          className="AlertElement__solve_alert_button"
          style={{
            right: buttonRightPosition,
          }}
          onClick={(e) => {
            e.stopPropagation();
            changeAlertState();
          }}
          disabled={isPending}
          title={getAlertButtonTitle(alert.solved)}
        >
          <Icon icon={getAlertButtonIcon(alert.solved)} />{" "}
          {getAlertButtonLabel(alert.solved)}
        </button>
      )}
    </button>
  );
};

export default AlertElement;
