import { Icon } from "@iconify/react";
import classNames from "classnames";
import { forwardRef, useEffect, useId, useState } from "react";

import SwitchGroup from "../SwitchGroup";
import "./TextAreaGroup.css";

interface TextAreaGroupProps
  extends Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, "onChange"> {
  onChange?: (value?: string | number | readonly string[]) => void;
  onFocus?: () => void;
  label: string;
  description?: string;
  error?: string;
}

const TextAreaGroup = forwardRef(function TextAreaGroup(
  {
    label,
    description,
    error,
    value,
    onChange,
    onFocus,
    ...textAreaProps
  }: TextAreaGroupProps,
  ref: React.ForwardedRef<HTMLTextAreaElement>,
) {
  const htmlId = useId();
  const [isCustom, setCustom] = useState(false);
  const [innerValue, setInnerValue] = useState(value);

  useEffect(() => {
    const ref = setTimeout(
      () => onChange?.(isCustom ? innerValue : undefined),
      isCustom ? 300 : 10,
    );
    return () => clearTimeout(ref);
  }, [isCustom, innerValue, onChange]);

  return (
    <div
      className={classNames("TextAreaGroup", {
        error: !!error,
        "TextAreaGroup--collapsed": !isCustom,
      })}
    >
      <SwitchGroup
        label={label}
        description={description}
        value={isCustom}
        onChange={setCustom}
        onFocus={onFocus}
      />
      <div className="TextAreaGroup__dynamic" tabIndex={-1}>
        <textarea
          id={htmlId}
          value={innerValue}
          onChange={(e) => setInnerValue(e.target.value)}
          onFocus={onFocus}
          {...textAreaProps}
          ref={ref}
          tabIndex={isCustom ? 0 : -1}
        />
        <div className="TextAreaGroup__error">
          <Icon
            className="TextAreaGroup__error_icon"
            icon="uil:exclamation-triangle"
          />
          <p className="TextAreaGroup__error_message">{error}</p>
        </div>
      </div>
    </div>
  );
});

export default TextAreaGroup;
