import { rankItem } from "@tanstack/match-sorter-utils";
import { createColumnHelper, FilterFn } from "@tanstack/react-table";
import * as _ from "lodash-es";

import { SuspensionRowData } from "api/hooks/useSuspensionsQuery";
import { humanizeMidDate } from "utils/date";

const columnHelper = createColumnHelper<SuspensionRowData>();

export const getColumns = () => [
  columnHelper.accessor("professionalName", {
    header: "Profesional",
    cell: (info) => (
      <strong className="SuspensionsTable__ellipsed">
        {_.startCase(info.getValue()?.toLowerCase())}
      </strong>
    ),
    sortingFn: "text",
    sortUndefined: "last",
  }),
  columnHelper.accessor("affectedAppointments", {
    header: "Citas afectadas",
    cell: (info) =>
      `${info.getValue()} cita${info.getValue() !== 1 ? "s" : ""}`,
    sortingFn: "alphanumeric",
    sortDescFirst: true,
  }),
  columnHelper.accessor("rangeStart", {
    header: "Rango de citas",
    cell: (info) => {
      const start = info.getValue();
      const end = info.row.original.rangeEnd;
      return start && end
        ? `${humanizeMidDate(new Date(start))} - ${humanizeMidDate(new Date(end))}`
        : null;
    },
    sortingFn: "datetime",
    sortDescFirst: true,
    sortUndefined: "last",
  }),
  columnHelper.accessor("centerName", {
    header: "Sucursal",
    cell: (info) => (
      <span className="SuspensionsTable__ellipsed">{info.getValue()}</span>
    ),
    sortingFn: "text",
    sortUndefined: "last",
  }),
  columnHelper.accessor("effectiveInteractionsRatio", {
    header: "Respondidas",
    cell: (info) => {
      const effective = info.row.original.effectiveInteractions;
      const total = info.row.original.affectedAppointments;
      return (
        <>
          <strong>{(info.getValue() * 100).toFixed(0)}%</strong> ({effective} de{" "}
          {total})
        </>
      );
    },
    sortingFn: "alphanumeric",
    sortDescFirst: true,
  }),
  columnHelper.accessor("createdAt", {
    header: "Fecha de envío",
    cell: (info) =>
      new Date(info.getValue()).toLocaleDateString("es-PY", {
        dateStyle: "medium",
      }),
    sortingFn: "datetime",
    sortDescFirst: true,
    sortUndefined: "last",
  }),
  columnHelper.accessor("createdBy", {
    header: "Creador (a)",
    cell: (info) => info.getValue(),
    sortingFn: "text",
    sortUndefined: "last",
  }),
];

export const fuzzyFilter: FilterFn<unknown> = (
  row,
  columnId,
  value,
  addMeta,
) => {
  const itemRank = rankItem(row.getValue(columnId), value);
  addMeta({
    itemRank,
  });
  return itemRank.passed;
};
