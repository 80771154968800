import { Icon } from "@iconify/react";
import { NavLink } from "react-router";

import { videos } from "components/Feedback/Tutoriales/helpers";

import "./VideoPlaceholder.css";

const VideoPlaceholder = () => {
  return (
    <div className="VideoPlaceholder">
      <NavLink to={Object.keys(videos)[0]} className="VideoPlaceholder__link">
        <Icon icon="mdi:playlist-play" />
      </NavLink>
      <p>Selecciona un video de la lista</p>
    </div>
  );
};

export default VideoPlaceholder;
