// TODO: move to ../../hooks/
import { useQuery } from "@tanstack/react-query";
import * as _ from "lodash-es";
import { useSelector } from "react-redux";

import { loginSelector } from "store/slices/login";

import { AlertType } from "../types/domain";

const alertTypes = [
  {
    id: "Equivocado: Entrega número correcto",
    name: "Número equivocado: envía corrección",
  },
  {
    id: "Número equivocado",
    name: "Número equivocado",
  },
  {
    id: "Paciente tiene pregunta o comentario",
    name: "Pregunta o comentario",
  },
  {
    id: "Paciente reagenda post confirmación",
    name: "Reagenda post confirmación",
  },
  {
    id: "Paciente cancela post confirmación",
    name: "Cancela post confirmación",
  },
  {
    id: "Paciente se arrepiente de cancelar su hora",
    name: "Se arrepiente de cancelar hora",
  },
  {
    id: "Mensaje post encuesta",
    name: "Mensaje post interacción",
  },
  {
    id: "Indica fallecimiento",
    name: "Indica fallecimiento",
  },
  {
    id: "Paciente quiere reagendar",
    name: "Quiere reagendar",
  },
  {
    id: "Derivación de examen o procedimiento",
    name: "Derivación",
  },
  {
    id: "Paciente quiere cambiar a telemedicina",
    name: "Paciente quiere cambiar a telemedicina",
  },
  {
    id: "Paciente cancela su cita",
    name: "Paciente cancela su cita",
  },
  {
    id: "Paciente envía imagen",
    name: "Paciente envía imagen",
  },
  {
    id: "Paciente no ha recibido el mensaje",
    name: "Paciente no ha recibido el mensaje",
  },
];

const useAlertTypesQuery = () => {
  const { clientName } = useSelector(loginSelector);
  return useQuery<AlertType[], unknown>({
    queryKey: ["alertTypes"],
    queryFn: async () => {
      let filteredAlertTypes = alertTypes;

      // NOTE: only the following list will receive `Derivación de examen o procedimiento`
      // opt-in
      if (
        clientName !== "NucleoSalud" &&
        clientName !== "Sanasalud" &&
        clientName !== "Interclínica" &&
        clientName !== "Andes Salud Chillán" &&
        clientName !== "Andes Salud Concepción" &&
        clientName !== "Andes Salud El Loa" &&
        clientName !== "Andes Salud Puerto Montt" &&
        clientName !== "CleverSalud" &&
        clientName !== "Isamédica" &&
        clientName !== "Clínica Santa María" &&
        clientName !== "Santa Maria Derivaciones" &&
        clientName !== "Clínica del Sur" &&
        clientName !== "Clínica Puerto Montt" &&
        clientName !== "Clínica Ciudad del Mar"
      ) {
        filteredAlertTypes = filteredAlertTypes.filter(
          (t) => t.id !== "Derivación de examen o procedimiento",
        );
      }

      // NOTE: only CEAPSI will receive `Paciente quiere cambiar a telemedicina`
      // opt-in
      if (clientName !== "CEAPSI") {
        filteredAlertTypes = filteredAlertTypes.filter(
          (t) => t.id !== "Paciente quiere cambiar a telemedicina",
        );
      }

      // NOTE: only Caminos IPS will receive `Paciente cancela su cita`
      // opt-in
      if (clientName !== "Caminos IPS") {
        filteredAlertTypes = filteredAlertTypes.filter(
          (t) => t.id !== "Paciente cancela su cita",
        );
      }

      // NOTE: only the following list will receive `Paciente envía imagen`
      // opt-in
      if (
        clientName !== "YohananTherapeutes" &&
        clientName !== "Clinyco" &&
        clientName !== "Andes Salud Chillán" &&
        clientName !== "Centauro" &&
        clientName !== "HolistiMED"
      ) {
        filteredAlertTypes = filteredAlertTypes.filter(
          (t) => t.id !== "Paciente envía imagen",
        );
      }

      // NOTE: only the following list will receive `Paciente no ha recibido el mensaje`
      // opt-in
      if (clientName !== "uandes") {
        filteredAlertTypes = filteredAlertTypes.filter(
          (t) => t.id !== "Paciente no ha recibido el mensaje",
        );
      }

      // NOTE: Clínica Santa María will NOT receive `Paciente cancela post confirmación` not `Mensaje post encuesta`
      // opt-out
      if (clientName === "Clínica Santa María") {
        filteredAlertTypes = filteredAlertTypes.filter(
          (t) =>
            t.id !== "Paciente cancela post confirmación" &&
            t.id !== "Mensaje post encuesta",
        );
      }

      // NOTE: the following list will NOT receive `Paciente tiene pregunta o comentario`
      // opt-out
      if (
        clientName === "Clínica Santa María" ||
        clientName === "Hospital de Angol"
      ) {
        filteredAlertTypes = filteredAlertTypes.filter(
          (t) => t.id !== "Paciente tiene pregunta o comentario",
        );
      }

      return _.sortBy(filteredAlertTypes, "name");
    },
  });
};

export default useAlertTypesQuery;
