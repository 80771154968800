import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";

import useAnswerHeadersQuery from "api/hooks/useAnswerHeadersQuery";
import Tooltip from "components/Tooltip";
import Button from "components/atoms/Button";
import { exportarTablaRespuestas } from "helpers/tablaRespuestas";
import useAnalytics from "hooks/useAnalytics";
import { encuestasSelector } from "store/slices/encuestas";
import {
  fijaTablaDestacada,
  respuestasSelector,
} from "store/slices/respuestas";

import "./ExportadorRespuestas.css";

interface ExportadorRespuestasProps {
  cargando: boolean;
}

const ExportadorRespuestas = ({ cargando }: ExportadorRespuestasProps) => {
  const {
    respuestasVisibles: respuestas,
    fechaInicio,
    fechaTermino,
  } = useSelector(respuestasSelector);
  const { data: headers } = useAnswerHeadersQuery();
  const { tipos, idEncuestaSeleccionada } = useSelector(encuestasSelector);
  const dispatch = useDispatch();
  const encuestaSeleccionada = tipos?.find(
    (t) => t.id === idEncuestaSeleccionada,
  );
  const track = useAnalytics();

  const descargarArchivo = () => {
    if (
      !headers ||
      !respuestas ||
      !idEncuestaSeleccionada ||
      !encuestaSeleccionada
    ) {
      return;
    }
    track("Feedback", "Respuestas", "exportarTabla", {
      idEncuesta: idEncuestaSeleccionada,
      encuesta: encuestaSeleccionada.nombre,
      fechaInicio: new Date(fechaInicio),
      fechaTermino: new Date(fechaTermino),
    });
    exportarTablaRespuestas(
      headers,
      respuestas,
      encuestaSeleccionada.nombre,
      fechaInicio,
      fechaTermino,
      idEncuestaSeleccionada,
      "xlsx",
    );
  };

  return (
    <div className="ExportadorRespuestas">
      <Tooltip text="Exportar tabla a Excel">
        <Button
          size="small"
          onClick={descargarArchivo}
          disabled={cargando || !respuestas?.length}
          onMouseEnter={() => dispatch(fijaTablaDestacada(true))}
          onMouseLeave={() => dispatch(fijaTablaDestacada(false))}
          icon={<Icon icon="mdi:table-arrow-down" />}
        >
          Exportar
        </Button>
      </Tooltip>
    </div>
  );
};

export default ExportadorRespuestas;
