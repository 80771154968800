import { formatISO } from "date-fns";
import { useContext, useState } from "react";
import { useDispatch } from "react-redux";

import useConfigFieldsQuery from "api/hooks/useConfigFieldsQuery";
import useCreateSuspensionMutation from "api/hooks/useCreateSuspensionMutation";
import Callout from "components/atoms/Callout";
import Loader from "components/atoms/Loader";
import { addToast } from "store/slices/toasts";

import {
  NewSuspensionContext,
  NewSuspensionDispatchContext,
} from "../NewSuspensionContext";
import { getErrorMessageForCode } from "../utils";
import ConfigureSuspensionMessagesForm from "./ConfigureSuspensionMessagesForm";
import "./ConfigureSuspensionMessagesStep.css";
import ConfirmModal from "./ConfirmModal/ConfirmModal";
import SuspensionMessagePreview from "./SuspensionMessagePreview/SuspensionMessagePreview";

interface ConfigureSuspensionMessagesStepProps {
  onSuccess: () => void;
}

const ConfigureSuspensionMessagesStep = ({
  onSuccess,
}: ConfigureSuspensionMessagesStepProps) => {
  const [suspensionMetas, setSuspensionMetas] = useState<{
    [k: string]: string | number | boolean | undefined | object;
  }>({});
  const {
    startDate,
    endDate,
    selectedProfessional,
    selectedAppointments,
    excludedCandidateIds,
    customMetas,
    reassignmentDates,
  } = useContext(NewSuspensionContext);
  const newSuspensionDispatch = useContext(NewSuspensionDispatchContext);
  if (!selectedProfessional || !selectedAppointments) {
    throw new Error("No professional or selected appointments selected");
  }

  const {
    data: configFields,
    isLoading,
    isError,
  } = useConfigFieldsQuery("create_suspension");

  const dispatch = useDispatch();

  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);

  const { mutate: createSuspension, isPending: isPendingCreateSuspension } =
    useCreateSuspensionMutation();

  const [selectedMessageId, setSelectedMessageId] = useState<string>("initial");

  const suspensionName = () => {
    const name = "Suspensión para " + selectedProfessional?.label;
    if (name.length > 64) {
      return name.substring(0, 64);
    }

    return name;
  };

  const handleCreateSuspension = () => {
    if (!selectedProfessional || !selectedAppointments) {
      dispatch(
        addToast({
          message: "Hubo un problema con el sistema, reintenta más tarde.",
          type: "error",
        }),
      );
      return;
    }
    createSuspension(
      {
        start: formatISO(startDate),
        end: formatISO(endDate),
        name: suspensionName(),
        professional_id: selectedProfessional.value,
        professional_name: selectedProfessional.label,
        excluded_appointment_ids: excludedCandidateIds,
        center_id: selectedProfessional.centerId,
        custom_external_meta: customMetas,
        reassignment_info: reassignmentDates
          ? Object.fromEntries(
              Object.entries(reassignmentDates).map(([appointmentId, date]) => [
                appointmentId,
                formatISO(date),
              ]),
            )
          : undefined,
      },
      {
        onSuccess: ({ poll_id: serviceId }) => {
          newSuspensionDispatch({
            type: "SET_SERVICE_ID",
            payload: serviceId,
          });
          onSuccess();
        },
        onError: (error) => {
          dispatch(
            addToast({
              message: getErrorMessageForCode(error),
              type: "error",
            }),
          );
        },
      },
    );
  };

  const onConfirmSuspension = () => {
    setConfirmModalOpen(false);
    handleCreateSuspension();
  };

  return isLoading ? (
    <Loader />
  ) : isError ? (
    <Callout variant="danger">
      Algo salió mal. Intenta de nuevo más tarde.
    </Callout>
  ) : (
    <div className="ConfigureSuspensionMessages">
      <div className="ConfigureSuspensionMessages__section">
        <h3 className="ConfigureSuspensionMessages__title">
          Configurar mensajes
        </h3>
        <p className="ConfigureSuspensionMessages__copy">
          En este último paso, puedes configurar los detalles específicos de la
          contactabilidad con los pacientes.
        </p>
      </div>
      <div className="ConfigureSuspensionMessages__main_row">
        <div className="ConfigureSuspensionMessages__main_row_section">
          {configFields && (
            <ConfigureSuspensionMessagesForm
              configFields={configFields}
              onSubmit={() => setConfirmModalOpen(true)}
              setSelectedMessageId={setSelectedMessageId}
              setSuspensionMetas={setSuspensionMetas}
            />
          )}
        </div>
        {!!selectedAppointments.length && (
          <SuspensionMessagePreview
            candidateAppointments={selectedAppointments}
            selectedMessageId={selectedMessageId}
            setSelectedMessageId={setSelectedMessageId}
            suspensionCustomMetas={suspensionMetas}
          />
        )}
      </div>
      <ConfirmModal
        isOpen={isConfirmModalOpen || isPendingCreateSuspension}
        isCreating={isPendingCreateSuspension}
        appointmentsCount={selectedAppointments.length}
        professionalName={selectedProfessional.label}
        start={startDate}
        end={endDate}
        onConfirm={onConfirmSuspension}
        onCancel={() => setConfirmModalOpen(false)}
      />
    </div>
  );
};

export default ConfigureSuspensionMessagesStep;
