import { addDays, endOfDay, format, startOfDay } from "date-fns";
import { useState, Fragment } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";

import Button from "components/atoms/Button";
import Collapsable from "components/molecules/Collapsable";
import InputGroup from "components/molecules/InputGroup";
import SwitchGroup from "components/molecules/SwitchGroup";
import TextAreaGroup from "components/molecules/TextAreaGroup";
import {
  CampaignConfig,
  GetConfigFieldsResponse as ConfigFields,
  PostSpreadsheetResponse,
} from "feedback-api";

import CampaignMessagePreview from "./CampaignMessagePreview";
import "./ConfigureCampaignStep.css";
import ConfirmModal from "./ConfirmModal";

export const DATETIME_LOCAL_FORMAT = "yyyy-LL-dd'T'HH:mm";

interface ConfigureCampaignStepProps {
  isLoading: boolean;
  campaignName: string;
  campaignStart: string;
  spreadsheetData: PostSpreadsheetResponse;
  fieldMapping: Record<string, string[]> | undefined;
  campaignConfigFields: ConfigFields;
  setCampaignConfig: (config: CampaignConfig) => void;
  onCreateCampaign: () => void;
}

const ConfigureCampaignStep = ({
  isLoading,
  campaignName,
  campaignStart,
  spreadsheetData,
  fieldMapping,
  campaignConfigFields,
  setCampaignConfig,
  onCreateCampaign,
}: ConfigureCampaignStepProps) => {
  const defaultValues = Object.fromEntries(
    [
      ...campaignConfigFields.standard_fields,
      ...campaignConfigFields.advanced_fields,
    ].map((field) => [field.meta_name, field.default_value]),
  );

  const [selectedMessageId, setSelectedMessageId] = useState<
    string | undefined
  >("initial");
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [isAdvancedSettingsOpen, setAdvancedSettingsOpen] =
    useState<boolean>(false);
  const { control, handleSubmit } = useForm({
    defaultValues: { campaignName, campaignStart, ...defaultValues } as {
      campaignName: string;
      campaignStart: string;
      [k: string]: string | boolean;
    },
    shouldUnregister: true,
  });

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    campaignName: _name,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    campaignStart: _start,
    ...campaignMetas
  } = useWatch({ control });

  const onSubmit = async ({
    campaignName: name,
    campaignStart,
    ...metas
  }: {
    campaignName: string;
    campaignStart: string;
  } & Record<string, string | boolean>) => {
    setCampaignConfig({
      name,
      start: new Date(campaignStart).toISOString(),
      metas,
      file_id: spreadsheetData.file_id,
    });
    setConfirmModalOpen(true);
  };

  const minDate = format(startOfDay(Date.now()), DATETIME_LOCAL_FORMAT);
  const maxDate = format(
    endOfDay(addDays(Date.now(), 7)),
    DATETIME_LOCAL_FORMAT,
  );

  return (
    <div className="ConfigureCampaignStep">
      <div className="ConfigureCampaignStep__main">
        <div className="ConfigureCampaignStep__header">
          <h3 className="ConfigureCampaignStep__header__title">
            Configurar campaña
          </h3>
          <p className="ConfigureCampaignStep__header__copy">
            En este último paso, puedes configurar los detalles específicos de
            tu campaña.
          </p>
        </div>
        <form
          className="ConfigureCampaignStep__form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            name="campaignName"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <InputGroup
                label="Nombre de la campaña"
                placeholder="Ingresa el nombre de tu campaña"
                error={error?.message}
                {...field}
              />
            )}
            rules={{
              required:
                "Este campo es obligatorio. Por favor, ingresa un nombre para tu campaña.",
            }}
          />
          <Controller
            name="campaignStart"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <InputGroup
                label="Fecha de inicio"
                placeholder="Selecciona la fecha de inicio"
                type="datetime-local"
                min={minDate}
                max={maxDate}
                error={error?.message}
                {...field}
              />
            )}
            rules={{
              required:
                "Este campo es obligatorio. Por favor, selecciona una fecha y hora de inicio.",
              min: {
                value: minDate,
                message: "La fecha de inicio debe ser igual o posterior a hoy.",
              },
              max: {
                value: maxDate,
                message:
                  "Puedes crear una campaña con a lo más una semana de anticipación.",
              },
            }}
          />
          {campaignConfigFields.standard_fields.map(
            ({ meta_name, display_name, type, description, message_id }) => (
              <Fragment key={meta_name}>
                {type === "switch" ? (
                  <Controller
                    name={meta_name}
                    control={control}
                    render={({ field }) => (
                      <SwitchGroup
                        label={display_name}
                        description={description}
                        value={field.value as boolean | undefined}
                        onChange={field.onChange}
                        onFocus={() =>
                          message_id && setSelectedMessageId(message_id)
                        }
                      />
                    )}
                  />
                ) : type === "textarea" ? (
                  <Controller
                    key={meta_name}
                    name={meta_name}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextAreaGroup
                        label={display_name}
                        description={description}
                        rows={4}
                        placeholder="Ingresa tu mensaje personalizado"
                        ref={field.ref}
                        value={field.value as string | undefined}
                        onChange={field.onChange}
                        onFocus={() =>
                          message_id && setSelectedMessageId(message_id)
                        }
                        error={error?.message}
                      />
                    )}
                    rules={{
                      validate: {
                        nonBlank: (v) =>
                          // @ts-expect-error: v will always be string | undefined
                          v?.trim() !== "" ||
                          "Agrega un nuevo texto para este mensaje, en caso contrario desactiva la personalización.",
                      },
                    }}
                  />
                ) : (
                  <Controller
                    key={meta_name}
                    name={meta_name}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <InputGroup
                        label={display_name}
                        description={description}
                        type={type}
                        placeholder="Ingresa tu mensaje personalizado"
                        ref={field.ref}
                        value={field.value as string | undefined}
                        onChange={field.onChange}
                        onFocus={() =>
                          message_id && setSelectedMessageId(message_id)
                        }
                        error={error?.message}
                        collapsable
                        startOpen={false}
                      />
                    )}
                    rules={{
                      validate: {
                        nonBlank: (v) =>
                          // @ts-expect-error: v will always be string | undefined
                          v?.trim() !== "" ||
                          "Agrega un nuevo texto para este mensaje, en caso contrario desactiva la personalización.",
                      },
                    }}
                  />
                )}
              </Fragment>
            ),
          )}
          {campaignConfigFields.advanced_fields.length > 0 && (
            <Collapsable
              isOpen={isAdvancedSettingsOpen}
              setOpen={setAdvancedSettingsOpen}
              title="Configuración avanzada"
              variant="flat"
            >
              <div className="ConfigureCampaignStep__form">
                {campaignConfigFields.advanced_fields.map(
                  ({
                    meta_name,
                    display_name,
                    type,
                    description,
                    message_id,
                  }) => (
                    <Fragment key={meta_name}>
                      {type === "switch" ? (
                        <Controller
                          name={meta_name}
                          control={control}
                          render={({ field }) => (
                            <SwitchGroup
                              label={display_name}
                              description={description}
                              value={field.value as boolean | undefined}
                              onChange={field.onChange}
                              onFocus={() => {
                                setAdvancedSettingsOpen(true);
                                if (message_id) {
                                  setSelectedMessageId(message_id);
                                }
                              }}
                            />
                          )}
                        />
                      ) : type === "textarea" ? (
                        <Controller
                          key={meta_name}
                          name={meta_name}
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <TextAreaGroup
                              label={display_name}
                              description={description}
                              rows={4}
                              placeholder="Ingresa tu mensaje personalizado"
                              ref={field.ref}
                              value={field.value as string | undefined}
                              onChange={field.onChange}
                              onFocus={() => {
                                setAdvancedSettingsOpen(true);
                                if (message_id) {
                                  setSelectedMessageId(message_id);
                                }
                              }}
                              error={error?.message}
                            />
                          )}
                          rules={{
                            validate: {
                              nonBlank: (v) =>
                                // @ts-expect-error: v will always be string | undefined
                                v?.trim() !== "" ||
                                "Agrega un nuevo texto para este mensaje, en caso contrario desactiva la personalización.",
                            },
                          }}
                        />
                      ) : (
                        <Controller
                          key={meta_name}
                          name={meta_name}
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <InputGroup
                              label={display_name}
                              description={description}
                              type={type}
                              placeholder="Ingresa tu mensaje personalizado"
                              ref={field.ref}
                              value={field.value as string | undefined}
                              onChange={field.onChange}
                              onFocus={() => {
                                setAdvancedSettingsOpen(true);
                                if (message_id) {
                                  setSelectedMessageId(message_id);
                                }
                              }}
                              error={error?.message}
                              collapsable
                              startOpen={false}
                            />
                          )}
                          rules={{
                            validate: {
                              nonBlank: (v) =>
                                // @ts-expect-error: v will always be string | undefined
                                v?.trim() !== "" ||
                                "Agrega un nuevo texto para este mensaje, en caso contrario desactiva la personalización.",
                            },
                          }}
                        />
                      )}
                    </Fragment>
                  ),
                )}
              </div>
            </Collapsable>
          )}
          <Button type="submit" disabled={isLoading} formNoValidate>
            Crear campaña
          </Button>
        </form>
      </div>
      <CampaignMessagePreview
        spreadsheetData={spreadsheetData}
        fieldMapping={fieldMapping}
        campaignMetas={campaignMetas}
        selectedMessageId={selectedMessageId}
        setSelectedMessageId={setSelectedMessageId}
      />
      <ConfirmModal
        disabled={isLoading}
        isOpen={isConfirmModalOpen}
        campaignStart={campaignStart}
        onConfirm={() => {
          setConfirmModalOpen(false);
          onCreateCampaign();
        }}
        onCancel={() => setConfirmModalOpen(false)}
        onClose={() => setConfirmModalOpen(false)}
      />
    </div>
  );
};

export default ConfigureCampaignStep;
