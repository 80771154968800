import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import useAnswerHeadersQuery from "api/hooks/useAnswerHeadersQuery";
import { Respuesta } from "api/types/domain";
import Skeleton from "components/Skeleton";
import { obtenerHeaders } from "helpers/tablaRespuestas";
import useAnalytics from "hooks/useAnalytics";
import { encuestasSelector } from "store/slices/encuestas";
import { guardaIdRespuesta, respuestasSelector } from "store/slices/respuestas";

import "./BodyTablaRespuestas.css";
import BodyTablaSinRespuestas from "./BodyTablaSinRespuestas";
import FilaTablaRespuestas from "./FilaTablaRespuestas";

interface BodyTablaRespuestasProps {
  respuestasPorPagina: number;
}

const BodyTablaRespuestas = ({
  respuestasPorPagina,
}: BodyTablaRespuestasProps) => {
  const { data: headers } = useAnswerHeadersQuery();
  const { idEncuestaSeleccionada: idEncuesta } = useSelector(encuestasSelector);
  const { respuestasVisibles: respuestas, pagina } =
    useSelector(respuestasSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const track = useAnalytics();

  const verChat = (respuesta: Respuesta, indice: number) => {
    track("Feedback", "Respuestas", "verChat", {
      interactionId: respuesta.interaction_id,
      idEncuesta,
      idUsuario: respuesta.user_id,
    });
    dispatch(guardaIdRespuesta(indice));
    navigate(
      `/respuestas/${idEncuesta}/${respuesta.user_id}?interaccion=${respuesta.interaction_id}`,
    );
  };

  const respuestasPagina =
    respuestas &&
    respuestas.slice(
      respuestasPorPagina * (pagina - 1),
      respuestasPorPagina * pagina,
    );

  const headersOrdenados = useMemo(
    () => (idEncuesta ? obtenerHeaders(headers, idEncuesta) : undefined),
    [headers, idEncuesta],
  );

  if (!headersOrdenados) {
    return null;
  }

  if (respuestas?.length === 0) {
    return <BodyTablaSinRespuestas />;
  }

  return (
    <tbody className="BodyTablaRespuestas">
      {respuestasPagina
        ? respuestasPagina.map((respuesta, i) => (
            <FilaTablaRespuestas
              key={`fila-respuestas-${respuesta.respuestaString}`}
              respuesta={respuesta}
              onClick={() =>
                verChat(respuesta, respuestasPorPagina * (pagina - 1) + i)
              }
              headers={headersOrdenados}
              indice={respuestasPorPagina * (pagina - 1) + i}
            />
          ))
        : Array(respuestasPorPagina)
            .fill(0)
            .map((x, i) => (
              <tr
                key={`fila-skeleton-respuestas-${i}`}
                className="BodyTablaRespuestas__fila"
              >
                <td style={{ width: "1.45rem" }}></td>
                {headersOrdenados.map((_, j) => (
                  <td
                    key={`celda-skeleton-respuesta-${i}-${j}`}
                    className="FilaTablaRespuestas__celda"
                  >
                    <Skeleton />
                  </td>
                ))}
              </tr>
            ))}
    </tbody>
  );
};

export default BodyTablaRespuestas;
