import { Icon } from "@iconify/react";
import { captureException } from "@sentry/react";
import { es } from "date-fns/locale";
import { FormEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { exportarRespuestas } from "api/endpoints";
import Loader from "components/atoms/Loader";
import { formatearNombreEncuesta } from "helpers/respuestas";
import useAnalytics from "hooks/useAnalytics";
import { encuestasSelector } from "store/slices/encuestas";
import { loginSelector } from "store/slices/login";
import { respuestasSelector } from "store/slices/respuestas";
import { addToast } from "store/slices/toasts";

import DatePicker from "../DatePicker";
import "./ExportacionAvanzada.css";
import ModalExportacionAvanzada from "./ModalExportacionAvanzada";

const tiposExportacion = [
  {
    nombre: "Excel",
    extension: "xlsx",
    icono: "mdi:microsoft-excel",
  },
  {
    nombre: "CSV",
    extension: "csv",
    icono: "mdi:file-delimited",
  },
] as const;

const ExportacionAvanzada = () => {
  const dispatch = useDispatch();
  const { fechaInicio, fechaTermino } = useSelector(respuestasSelector);
  const { clientName } = useSelector(loginSelector);
  const [inicio, setInicio] = useState(new Date(fechaInicio));
  const [termino, setTermino] = useState(new Date(fechaTermino));
  const [email, setEmail] = useState("");
  const [exportando, setExportando] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [indiceExtensionSeleccionado, setIndiceExtensionSeleccionado] =
    useState(0);
  const { tipos } = useSelector(encuestasSelector);
  const [idEncuestaSeleccionada, setIdEncuestaSeleccionada] = useState(
    tipos?.[0].id,
  );
  const emailRef = useRef<HTMLInputElement>(null);
  const track = useAnalytics();

  const exportar = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (exportando || !idEncuestaSeleccionada) {
      return;
    }
    setExportando(true);
    exportarRespuestas(
      idEncuestaSeleccionada,
      inicio,
      termino,
      email,
      tiposExportacion[indiceExtensionSeleccionado].extension,
    )
      .then(() => {
        track("Feedback", "Reporte", "exportar", {
          idEncuestaSeleccionada,
          inicio,
          termino,
          email,
          extension: tiposExportacion[indiceExtensionSeleccionado].extension,
        });
        setModalVisible(true);
      })
      .catch((err) => {
        captureException(err);
        dispatch(
          addToast({
            message: "¡Ups! Algo salió mal. Intenta de nuevo más tarde.",
            type: "error",
          }),
        );
      })
      .finally(() => setExportando(false));
  };

  useEffect(() => {
    if (termino < inicio) {
      setInicio(termino);
      setTermino(inicio);
    }
  }, [inicio, termino]);

  useEffect(() => track("Feedback", "Reporte", "index"), [track]);

  if (!tipos) {
    return null;
  }

  return (
    <div className="ExportacionAvanzada">
      {tiposExportacion.map((t, i) => (
        <Icon
          key={`preload-icono-${i}`}
          icon={t.icono}
          style={{ display: "none" }}
        />
      ))}
      <ModalExportacionAvanzada
        email={email}
        visible={modalVisible}
        ocultar={() => setModalVisible(false)}
      />
      <div className="ExportacionAvanzada__contenedor">
        <div className="ExportacionAvanzada__tarjeta">
          <h1 className="ExportacionAvanzada__titulo">Reporte completo</h1>
          <div className="ExportacionAvanzada__explicacion">
            <p>
              Este módulo permite exportar la lista de chats de un servicio a
              una planilla de datos.
              <br />
              <br />
              Recibirás la planilla en el email ingresado.
            </p>
            <div className="ExportacionAvanzada__diagrama">
              <Icon
                icon={tiposExportacion[indiceExtensionSeleccionado].icono}
              />
              <Icon icon="mdi:arrow-right-thick" />
              <Icon
                onClick={() => emailRef.current?.focus()}
                icon="mdi:email"
              />
            </div>
          </div>
          <form
            className="ExportacionAvanzada__contenedor_formulario"
            onSubmit={exportar}
          >
            <div className="ExportacionAvanzada__campo">
              <label className="ExportacionAvanzada__label" htmlFor="email">
                E-mail
              </label>
              <input
                autoFocus
                className="ExportacionAvanzada__input"
                type="email"
                id="email"
                required
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                ref={emailRef}
                placeholder="Ingresa tu e-mail"
              />
            </div>
            <div className="ExportacionAvanzada__campo">
              <label className="ExportacionAvanzada__label" htmlFor="servicio">
                Servicio
              </label>
              <select
                id="servicio"
                onChange={(e) =>
                  setIdEncuestaSeleccionada(Number(e.target.value))
                }
                className="ExportacionAvanzada__input"
              >
                {tipos.map((t) => (
                  <option key={`option-tipo-${t.id}`} value={t.id}>
                    {formatearNombreEncuesta(clientName, t.nombre)}
                  </option>
                ))}
              </select>
            </div>
            <div className="ExportacionAvanzada__campo">
              <label className="ExportacionAvanzada__label" htmlFor="periodo">
                Rango de fechas
              </label>
              <div className="ExportacionAvanzada__contenedor_rango">
                <DatePicker
                  selected={inicio}
                  onChange={(f) => setInicio(f!)}
                  dateFormat="d MMMM yyyy"
                  locale={es}
                  className="ExportacionAvanzada__datepicker"
                  required
                />
                -
                <DatePicker
                  selected={termino}
                  onChange={(f) => setTermino(f!)}
                  dateFormat="d MMMM yyyy"
                  locale={es}
                  className="ExportacionAvanzada__datepicker"
                  required
                />
              </div>
            </div>
            <div className="ExportacionAvanzada__campo">
              <label className="ExportacionAvanzada__label" htmlFor="periodo">
                Formato
              </label>
              <div className="ExportacionAvanzada__contenedor_radio">
                {tiposExportacion.map((tipo, i) => (
                  <label
                    key={`radio-exportacion-${i}`}
                    className="ExportacionAvanzada__label_option"
                  >
                    <input
                      onChange={() => setIndiceExtensionSeleccionado(i)}
                      type="radio"
                      radioGroup="formato"
                      checked={indiceExtensionSeleccionado === i}
                    />{" "}
                    {tipo.nombre}
                  </label>
                ))}
              </div>
            </div>
            <button
              className="ExportacionAvanzada__boton_exportar"
              type="submit"
              disabled={exportando}
            >
              {exportando ? (
                <>
                  <Loader color="#fff" size="1rem" /> Generando...
                </>
              ) : (
                <>Generar reporte</>
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ExportacionAvanzada;
