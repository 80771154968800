import { useQuery } from "@tanstack/react-query";
import { addMonths, differenceInDays, format, parse } from "date-fns";
import { es } from "date-fns/locale";
import { useSelector } from "react-redux";

import { debtorsApi } from "feedback-api";
import { loginSelector } from "store/slices/login";

const oneHourInMS = 1 * 60 * 60 * 1_000;
const daysForAlmostExpiredStatus = 7;

type PaymentStatus =
  | {
      status: "EXPIRED";
      documentServiceMonth: string;
    }
  | {
      status: "ALMOST_EXPIRED";
      documentServiceMonth: string;
      daysLeft: number;
    }
  | { status: "NOT_EXPIRED" }
  | { status: "NON_CHILEAN_EXPIRED" };

const debtorClients = ["DentalTotal", "Medfam"];

const ignoreClients = ["NucleoSalud"];

const useIsClientDebtorQuery = () => {
  const { clientName } = useSelector(loginSelector);
  return useQuery<PaymentStatus, unknown>({
    queryKey: ["isDebtor", clientName],
    queryFn: async ({ signal }) => {
      if (!clientName) {
        return { status: "NOT_EXPIRED" };
      }

      const ignoredClient = ignoreClients.includes(clientName);
      if (ignoredClient) {
        return { status: "NOT_EXPIRED" };
      }

      const nonChileanDebtorClient = debtorClients.includes(clientName);
      if (nonChileanDebtorClient) {
        return { status: "NON_CHILEAN_EXPIRED" };
      }

      const { data: debtData } = await debtorsApi.getDebtorDataV4DebtorsGet({
        signal,
      });

      if (debtData.mostExpiredDocumentIssueDate) {
        const documentServiceMonth = formatDocumentIssueMonth(
          debtData.mostExpiredDocumentIssueDate,
        );
        return {
          status: "EXPIRED",
          documentServiceMonth,
        };
      }
      if (debtData.nearestDueDate) {
        const daysLeft =
          differenceInDays(
            parse(debtData.nearestDueDate, "yyyy-MM-dd", new Date()),
            new Date(),
          ) + 1;
        if (daysLeft <= daysForAlmostExpiredStatus) {
          const documentServiceMonth = formatDocumentIssueMonth(
            debtData.nearestDueDateIssueDate!,
          );
          return {
            status: "ALMOST_EXPIRED",
            documentServiceMonth,
            daysLeft,
          };
        }
      }
      return { status: "NOT_EXPIRED" };
    },
    refetchInterval: oneHourInMS,
    refetchOnWindowFocus: false,
  });
};

const formatDocumentIssueMonth = (dateISO: string) =>
  format(addMonths(parse(dateISO, "yyyy-MM-dd", new Date()), -1), "MMMM", {
    locale: es,
  });

export default useIsClientDebtorQuery;
