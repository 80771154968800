import { Icon } from "@iconify/react";
import classNames from "classnames";
import { format, startOfToday } from "date-fns";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import useGetProfessionalsDataQuery from "api/hooks/useGetProfessionalsDataQuery";
import Button from "components/atoms/Button";
import Callout from "components/atoms/Callout";
import Input from "components/atoms/Input";
import SelectGroup from "components/molecules/SelectGroup";

import { ProfessionalOption } from "../NewSuspension";
import {
  NewSuspensionContext,
  NewSuspensionDispatchContext,
} from "../NewSuspensionContext";
import "./ConfigureSuspensionStep.css";

const DATETIME_LOCAL_FORMAT = "yyyy-LL-dd'T'HH:mm";

interface ConfigureSuspensionStepProps {
  onSubmit: () => void;
}

const ConfigureSuspensionStep = ({
  onSubmit,
}: ConfigureSuspensionStepProps) => {
  const { startDate, endDate, selectedProfessional, suspensionSettingType } =
    useContext(NewSuspensionContext);
  const newSuspensionDispatch = useContext(NewSuspensionDispatchContext);
  const formattedStartOfToday = format(startOfToday(), DATETIME_LOCAL_FORMAT);

  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");

  useEffect(() => {
    const timeoutId = setTimeout(() => setDebouncedQuery(query), 300);
    return () => clearTimeout(timeoutId);
  }, [query]);

  const {
    data: professionalsData,
    isError,
    isPending,
  } = useGetProfessionalsDataQuery(debouncedQuery);

  type Inputs = {
    formStartDate: string;
    formEndDate: string;
    selectedProfessional: ProfessionalOption;
  };

  const { control, getValues, handleSubmit, formState } = useForm({
    defaultValues: {
      formStartDate: format(startDate, DATETIME_LOCAL_FORMAT),
      formEndDate: format(endDate, DATETIME_LOCAL_FORMAT),
      selectedProfessional,
    } as Inputs,
    shouldUnregister: true,
  });

  const onSubmitSuccess = ({
    formStartDate,
    formEndDate,
    selectedProfessional,
  }: Inputs) => {
    newSuspensionDispatch({
      type: "SET_START_DATE",
      payload: new Date(formStartDate),
    });
    newSuspensionDispatch({
      type: "SET_END_DATE",
      payload: new Date(formEndDate),
    });
    newSuspensionDispatch({
      type: "SET_SELECTED_PROFESSIONAL",
      payload: selectedProfessional,
    });
    onSubmit();
  };

  if (isError) {
    return <Callout variant="danger">Error al cargar profesionales.</Callout>;
  }
  return (
    <div className="UploadSpreadsheetStep">
      <div className="UploadSpreadsheetStep__section">
        <h3 className="UploadSpreadsheetStep__title">Configurar suspensión</h3>
        <p className="UploadSpreadsheetStep__copy">
          Define los criterios para identificar las citas afectadas por la
          suspensión
        </p>
      </div>
      <form
        className="ConfigureSuspensionStep__form"
        onSubmit={handleSubmit(onSubmitSuccess)}
      >
        <div className="InputGroup error">
          <div className="InputGroup__context">
            <p className="InputGroup__label">Periodo de suspensión</p>
            <p className="InputGroup__description">
              Selecciona el rango de fecha y hora para la suspensión, incluyendo
              el inicio y el término del periodo afectado.
            </p>
          </div>
          <div className="InputGroup__dynamic">
            <div className="ConfigureSuspensionStep__range_config">
              <div className="ConfigureSuspensionStep__range_config__section">
                <label
                  htmlFor="start-date"
                  className="ConfigureSuspensionStep__range_config__label"
                >
                  Fecha de inicio
                </label>
                <Controller
                  name="formStartDate"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      id="start-date"
                      className={classNames(
                        "ConfigureSuspensionStep__range_config__input",
                        { invalid: !!error },
                      )}
                      placeholder="Selecciona la fecha de inicio"
                      type="datetime-local"
                      min={formattedStartOfToday}
                      {...field}
                    />
                  )}
                  rules={{
                    required:
                      "Este campo es obligatorio. Por favor, selecciona una fecha y hora de inicio.",
                    min: {
                      value: formattedStartOfToday,
                      message:
                        "La fecha de inicio debe ser igual o posterior a hoy.",
                    },
                    deps: ["formEndDate"],
                  }}
                />
              </div>
              <div className="ConfigureSuspensionStep__range_config__section">
                <label
                  htmlFor="end-date"
                  className="ConfigureSuspensionStep__range_config__label"
                >
                  Fecha de término
                </label>
                <Controller
                  name="formEndDate"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      id="end-date"
                      className={classNames(
                        "ConfigureSuspensionStep__range_config__input",
                        { invalid: !!error },
                      )}
                      placeholder="Selecciona la fecha de término"
                      type="datetime-local"
                      min={formattedStartOfToday}
                      {...field}
                    />
                  )}
                  rules={{
                    required:
                      "Este campo es obligatorio. Por favor, selecciona una fecha y hora de término.",
                    validate: (v) =>
                      v >= getValues("formStartDate") ||
                      "La fecha de término debe ser igual o posterior a la fecha de inicio.",
                  }}
                />
              </div>
            </div>
            {formState.errors.formStartDate && (
              <div className="InputGroup__error">
                <Icon
                  className="InputGroup__error_icon"
                  icon="uil:exclamation-triangle"
                />
                <p className="InputGroup__error_message">
                  {formState.errors.formStartDate.message}
                </p>
              </div>
            )}
            {formState.errors.formEndDate && (
              <div className="InputGroup__error">
                <Icon
                  className="InputGroup__error_icon"
                  icon="uil:exclamation-triangle"
                />
                <p className="InputGroup__error_message">
                  {formState.errors.formEndDate.message}
                </p>
              </div>
            )}
          </div>
        </div>
        <Controller
          name="selectedProfessional"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <SelectGroup
              label="Selecciona al profesional"
              description="Elige al profesional que no podrá atender sus citas durante el periodo seleccionado."
              placeholder="Selecciona un profesional"
              menuPortalTarget={document.body}
              options={professionalsData?.map((professionalData) => ({
                label: professionalData.display_data,
                value: professionalData.search_data,
                centerId: professionalData.center_id,
              }))}
              filterOption={null}
              isLoading={isPending}
              loadingMessage={() => "Cargando profesionales..."}
              onChange={onChange}
              onInputChange={setQuery}
              value={value}
              error={error?.message}
            />
          )}
          rules={{
            required:
              "Este campo es obligatorio. Por favor, selecciona un profesional.",
          }}
        />
        <Button type="submit" disabled={!suspensionSettingType} formNoValidate>
          Verificar pacientes
        </Button>
      </form>
    </div>
  );
};

export default ConfigureSuspensionStep;
