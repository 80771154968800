import { Icon } from "@iconify/react";
import { rankItem } from "@tanstack/match-sorter-utils";
import {
  ColumnDef,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  RowSelectionState,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";

import Skeleton from "components/Skeleton";
import Table from "components/atoms/Table";

import "./ReassignmentTable.css";

const fuzzyFilter: FilterFn<unknown> = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);
  addMeta({
    itemRank,
  });
  return itemRank.passed;
};

type ReassignmentTableProps<T> = {
  appointments: T[] | undefined;
  columns: ColumnDef<T, string>[];
  rowSelection?: RowSelectionState;
  getRowId?: (row: T) => string;
};

const ReassignmentTable = <T extends object>({
  appointments,
  columns,
  rowSelection,
  getRowId,
}: ReassignmentTableProps<T>) => {
  const table = useReactTable({
    data: appointments ?? [],
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId,
    state: { rowSelection },
  });

  const { rows } = table.getRowModel();

  if (!appointments) {
    return (
      <div className="ReassignmentTable ReassignmentTable--loading">
        <Table>
          <thead>
            <tr>
              {Array.from({ length: 7 }).map((_, j) => (
                <th key={j}>
                  <div style={{ display: "flex" }}>
                    <Skeleton />
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: 10 }).map((_, i) => (
              <tr key={i}>
                {Array.from({ length: 7 }).map((_, j) => (
                  <td key={j}>
                    <div style={{ display: "flex" }}>
                      <Skeleton />
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  }

  return (
    <div className="ReassignmentTable">
      <Table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  className={classNames({
                    ReassignmentTable__sortable: header.column.getCanSort(),
                  })}
                >
                  <div className="ReassignmentTable__header_wrapper">
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                    {header.column.getIsSorted() === "asc" && (
                      <Icon
                        className="ReassignmentTable__sort_icon"
                        icon="uil:arrow-up"
                      />
                    )}
                    {header.column.getIsSorted() === "desc" && (
                      <Icon
                        className="ReassignmentTable__sort_icon"
                        icon="uil:arrow-down"
                      />
                    )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {rows.map((row) => (
            <tr
              className={classNames({
                "ReassignmentTable__table_row--disabled":
                  rowSelection && !rowSelection[row.id],
              })}
              key={row.id}
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ReassignmentTable;
