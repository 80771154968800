import { ReactNode } from "react";

import "./EmptyStateCard.css";

interface EmptyStateCardProps {
  title: string;
  description: string;
  action: ReactNode;
  preview: ReactNode;
}

const EmptyStateCard = ({
  title,
  description,
  action,
  preview,
}: EmptyStateCardProps) => (
  <div className="EmptyStateCard">
    <div className="EmptyStateCard_left">{preview}</div>
    <div className="EmptyStateCard_right">
      <div className="EmptyStateCard_content">
        <h2 className="EmptyStateCard_title">{title}</h2>
        <p className="EmptyStateCard_description">{description}</p>
      </div>
      {action}
    </div>
  </div>
);

export default EmptyStateCard;
