import { useMemo, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Fragment } from "react/jsx-runtime";

import useUpdateGlobalSettingsMutation from "api/hooks/useUpdateGlobalSettingsMutation";
import Button from "components/atoms/Button";
import Loader from "components/atoms/Loader";
import Collapsable from "components/molecules/Collapsable";
import InputGroup from "components/molecules/InputGroup";
import SelectGroup from "components/molecules/SelectGroup";
import SwitchGroup from "components/molecules/SwitchGroup";
import TextAreaGroup from "components/molecules/TextAreaGroup";
import { GetConfigFieldsResponse, SelectOption } from "feedback-api";
import { addToast } from "store/slices/toasts";

import "./GeneralConfigurationForm.css";
import GeneralConfigurationFormPreview from "./GeneralConfigurationFormPreview/GeneralConfigurationFormPreview";

interface GeneralConfigurationFormProps {
  configFields: GetConfigFieldsResponse;
  onClose: () => void;
  drawerOpen: boolean;
}

const GeneralConfigurationForm = ({
  configFields,
  onClose,
  drawerOpen,
}: GeneralConfigurationFormProps) => {
  const defaultValues = Object.fromEntries(
    [...configFields.standard_fields, ...configFields.advanced_fields].map(
      (field) => [field.meta_name, field.default_value],
    ),
  );

  const [isAdvancedSettingsOpen, setAdvancedSettingsOpen] = useState(true);

  const { control, handleSubmit } = useForm({
    defaultValues,
    shouldUnregister: true,
  });
  const suspensionMetas = useWatch({ control });

  const {
    mutate: updateGlobalSettings,
    isPending: isPendingUpdateGlobalSettings,
  } = useUpdateGlobalSettingsMutation();

  const customMetasWithoutUndefined = useMemo(() => {
    return Object.fromEntries(
      Object.entries(suspensionMetas).map(([key, value]) => [key, value ?? ""]),
    );
  }, [suspensionMetas]);

  const dispatch = useDispatch();

  const onSuccess = () => {
    dispatch(
      addToast({
        message: "Configuración actualizada correctamente",
        type: "success",
      }),
    );
    onClose();
  };
  const submitForm = () => {
    updateGlobalSettings(
      {
        custom_external_meta: customMetasWithoutUndefined,
      },
      {
        onSuccess,
        onError: () => {
          dispatch(
            addToast({
              message:
                "No se pudo actualizar la configuración. Por favor intenta de nuevo más tarde.",
              type: "error",
            }),
          );
        },
      },
    );
  };

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <div className="GeneralConfigurationForm">
        {configFields?.standard_fields.map((configField) => (
          <div
            key={configField.meta_name}
            className="GeneralConfigurationForm__name_format"
          >
            {configField.type === "switch" ? (
              <Controller
                name={configField.meta_name}
                control={control}
                render={({ field }) => (
                  <SwitchGroup
                    label={configField.display_name}
                    description={configField.description}
                    value={field.value as boolean}
                    onChange={field.onChange}
                  />
                )}
              />
            ) : configField.type === "textarea" ? (
              <Controller
                key={configField.meta_name}
                name={configField.meta_name}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextAreaGroup
                    label={configField.display_name}
                    description={configField.description}
                    rows={4}
                    placeholder="Ingresa tu mensaje personalizado"
                    ref={field.ref}
                    value={field.value as string}
                    onChange={field.onChange}
                    error={error?.message}
                  />
                )}
                rules={{
                  validate: {
                    nonBlank: (v) =>
                      // @ts-expect-error: v will always be string | undefined
                      v?.trim() !== "" ||
                      "Agrega un nuevo texto para este mensaje, en caso contrario desactiva la personalización.",
                  },
                }}
              />
            ) : configField.type === "select" ? (
              <Controller
                key={configField.meta_name}
                name={configField.meta_name}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <SelectGroup
                    label={configField.display_name}
                    description={configField.description}
                    placeholder="Selecciona una opción"
                    error={error?.message}
                    options={configField.options}
                    onChange={(newValue) =>
                      field.onChange((newValue as SelectOption).value)
                    }
                    value={configField.options.find(
                      (option) => option.value === field.value,
                    )}
                    menuPosition={drawerOpen ? "fixed" : "absolute"}
                    menuPortalTarget={null}
                  />
                )}
                rules={{
                  required: "Selecciona alguna de las opciones.",
                }}
              />
            ) : (
              <Controller
                key={configField.meta_name}
                name={configField.meta_name}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <InputGroup
                    label={configField.display_name}
                    description={configField.description}
                    type={configField.type}
                    placeholder="Ingresa tu mensaje personalizado"
                    ref={field.ref}
                    value={field.value as string}
                    onChange={field.onChange}
                    error={error?.message}
                    collapsable
                  />
                )}
                rules={{
                  validate: {
                    nonBlank: (v) =>
                      // @ts-expect-error: v will always be string | undefined
                      v?.trim() !== "" ||
                      "Agrega un nuevo texto para este mensaje, en caso contrario desactiva la personalización.",
                  },
                }}
              />
            )}

            <GeneralConfigurationFormPreview
              customMetas={customMetasWithoutUndefined}
              previewType="company_name_preview"
            />
          </div>
        ))}
        <div className="GeneralConfigurationForm__advanced">
          {configFields?.advanced_fields.length > 0 && (
            <Collapsable
              isOpen={isAdvancedSettingsOpen}
              setOpen={setAdvancedSettingsOpen}
              title="Personalización de mensajes"
            >
              <div className="GeneralConfigurationForm__advanced__content">
                <p className="GeneralConfigurationForm__advanced__content_title">
                  Selecciona los elementos que se incluirán en los mensajes
                  enviados al paciente.
                </p>
                <div className="GeneralConfigurationForm__advanced__content_inputs">
                  {configFields?.advanced_fields.map((configField) => (
                    <Fragment key={configField.meta_name}>
                      {configField.type === "switch" ? (
                        <Controller
                          name={configField.meta_name}
                          control={control}
                          render={({ field }) => (
                            <SwitchGroup
                              label={configField.display_name}
                              description={configField.description}
                              value={field.value as boolean}
                              onChange={field.onChange}
                            />
                          )}
                        />
                      ) : configField.type === "textarea" ? (
                        <Controller
                          key={configField.meta_name}
                          name={configField.meta_name}
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <TextAreaGroup
                              label={configField.display_name}
                              description={configField.description}
                              rows={4}
                              placeholder="Ingresa tu mensaje personalizado"
                              ref={field.ref}
                              value={field.value as string}
                              onChange={field.onChange}
                              error={error?.message}
                            />
                          )}
                          rules={{
                            validate: {
                              nonBlank: (v) =>
                                // @ts-expect-error: v will always be string | undefined
                                v?.trim() !== "" ||
                                "Agrega un nuevo texto para este mensaje, en caso contrario desactiva la personalización.",
                            },
                          }}
                        />
                      ) : configField.type === "select" ? (
                        <Controller
                          key={configField.meta_name}
                          name={configField.meta_name}
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <SelectGroup
                              label={configField.display_name}
                              description={configField.description}
                              placeholder="Selecciona una opción"
                              error={error?.message}
                              options={configField.options}
                              onChange={(newValue) =>
                                field.onChange((newValue as SelectOption).value)
                              }
                              value={configField.options.find(
                                (option) => option.value === field.value,
                              )}
                              menuPortalTarget={document.body}
                            />
                          )}
                          rules={{
                            required: "Selecciona alguna de las opciones.",
                          }}
                        />
                      ) : (
                        <Controller
                          key={configField.meta_name}
                          name={configField.meta_name}
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <InputGroup
                              label={configField.display_name}
                              description={configField.description}
                              type={configField.type}
                              placeholder="Ingresa tu mensaje personalizado"
                              ref={field.ref}
                              value={field.value as string}
                              onChange={field.onChange}
                              error={error?.message}
                              collapsable
                            />
                          )}
                          rules={{
                            validate: {
                              nonBlank: (v) =>
                                // @ts-expect-error: v will always be string | undefined
                                v?.trim() !== "" ||
                                "Agrega un nuevo texto para este mensaje, en caso contrario desactiva la personalización.",
                            },
                          }}
                        />
                      )}
                    </Fragment>
                  ))}
                </div>
              </div>
            </Collapsable>
          )}
        </div>
      </div>
      <div className="GeneralConfigurationForm__buttons">
        <Button
          variant="outline"
          onClick={onClose}
          disabled={isPendingUpdateGlobalSettings}
        >
          Cancelar
        </Button>
        <Button
          type="submit"
          formNoValidate
          disabled={isPendingUpdateGlobalSettings}
        >
          {isPendingUpdateGlobalSettings && <Loader color="#fff" size="1rem" />}
          Guardar cambios
        </Button>
      </div>
    </form>
  );
};
export default GeneralConfigurationForm;
