import { InlineIcon } from "@iconify/react";
import { captureException } from "@sentry/react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";

import { agregarReaccion, obtenerReacciones } from "api/endpoints";
import { Reaction } from "api/types/responses";
import { agregaReaccionARespuesta } from "store/slices/respuestas";

import LoaderChat from "../LoaderChat";
import FilaNota from "./FilaNota";
import FormularioNuevaNota from "./FormularioNuevaNota";
import "./NotasChat.css";

interface NotasChatProps {
  start: string | undefined;
}

const NotasChat = ({ start }: NotasChatProps) => {
  const { serviceId, patientId } = useParams();
  const [reacciones, setReacciones] = useState<Reaction[]>();
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (refresh && serviceId && patientId && start) {
      setRefresh(false);
      obtenerReacciones(serviceId, patientId, start).then(({ data }) => {
        setReacciones(data.data);
      });
    }
  }, [refresh, serviceId, patientId, start]);

  useEffect(() => {
    setReacciones(undefined);
    if (serviceId && patientId && start) {
      obtenerReacciones(serviceId, patientId, start).then(({ data }) => {
        setReacciones(data.data);
      });
    }
  }, [serviceId, patientId, start]);

  if (!reacciones || !serviceId || !patientId || !start) {
    return (
      <div className="NotasChat">
        <div className="NotasChat__superior">
          <h2 className="NotasChat__titulo">
            Notas{" "}
            <InlineIcon
              style={{ fontSize: ".8rem" }}
              icon="mdi:note-text-outline"
            />
          </h2>
        </div>
        <LoaderChat />
      </div>
    );
  }

  const agregarNota = (emoji: string, texto: string) => {
    setReacciones(undefined);
    agregarReaccion(serviceId, patientId, start, emoji, texto)
      .then(() => {
        dispatch(
          agregaReaccionARespuesta({ idUsuario: patientId, emoji, texto }),
        );
        setRefresh(true);
      })
      .catch((error) => {
        captureException(error);
        setRefresh(true);
      });
  };

  reacciones.sort((r1, r2) => (r1.created_at > r2.created_at ? 1 : -1));

  return (
    <div className="NotasChat">
      <div className="NotasChat__superior">
        <h2 className="NotasChat__titulo">
          Notas{" "}
          <InlineIcon
            style={{ fontSize: ".8rem" }}
            icon="mdi:note-text-outline"
          />
        </h2>
      </div>
      <div className="NotasChat__contenedor_lista">
        {reacciones.map((reaccion) => (
          <FilaNota
            key={`reaccion-chat-${reaccion.id}`}
            reaccion={reaccion}
            refrescar={() => setRefresh(true)}
          />
        ))}
        <FormularioNuevaNota agregarNota={agregarNota} />
      </div>
    </div>
  );
};

export default NotasChat;
