import { Icon } from "@iconify/react";
import classNames from "classnames";
import { useSelector } from "react-redux";

import { formatearNombreEncuesta } from "helpers/respuestas";
import useAnalytics from "hooks/useAnalytics";
import { encuestasSelector } from "store/slices/encuestas";
import { loginSelector } from "store/slices/login";

import "./TabsEncuestas.css";

interface TabsEncuestasProps {
  tiposOrdenados: (
    | {
        id: number;
        nombre: string;
        enabled: boolean;
        integrations: {
          start_time: string;
          display_name: string;
          type: string;
        }[];
        sucursales: string[];
      }
    | {
        id: string;
        nombre: string;
        propiedad: unknown;
        enabled: boolean;
      }
  )[];
  verEncuesta: (id: number | string) => Promise<void>;
}

const TabsEncuestas = ({ tiposOrdenados, verEncuesta }: TabsEncuestasProps) => {
  const { idEncuestaSeleccionada } = useSelector(encuestasSelector);
  const { clientName } = useSelector(loginSelector);
  const track = useAnalytics();

  return (
    <div className="TabsEncuestas">
      {tiposOrdenados.map((tipo) => (
        <button
          key={`boton-tab-${tipo.id}`}
          onClick={() => {
            track("Feedback", "Respuestas", "verEncuestaConTabs", {
              idEncuesta: tipo.id,
              nombreEncuesta: tipo.nombre,
            });
            verEncuesta(tipo.id);
          }}
          className={classNames({
            TabsEncuestas__tab: true,
            "TabsEncuestas__tab--activo": idEncuestaSeleccionada === tipo.id,
          })}
        >
          <Icon
            className="TabsEncuestas__tab_icono_empresa"
            icon="mdi:whatsapp"
          />
          {formatearNombreEncuesta(clientName, tipo.nombre)}
        </button>
      ))}
    </div>
  );
};

export default TabsEncuestas;
