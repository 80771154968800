import { InlineIcon } from "@iconify/react";
import classNames from "classnames";
import { useState } from "react";
import { useSelector } from "react-redux";

import Scrambler from "components/Scrambler/Scrambler";
import { loginSelector } from "store/slices/login";
import { scramblerSelector } from "store/slices/scrambler";

import "./MenuUsuario.css";
import PopupMenuUsuario from "./PopupMenuUsuario";

const MenuUsuario = () => {
  const [mostrarMenu, setMostrarMenu] = useState(false);
  const { scrambled } = useSelector(scramblerSelector);
  const { clientName } = useSelector(loginSelector);

  return (
    <>
      <div
        className={classNames({
          MenuUsuario: true,
          "MenuUsuario--incognito": scrambled,
        })}
      >
        <button
          className="MenuUsuario__boton_mostrar_popup"
          onClick={() => setMostrarMenu(true)}
        >
          <InlineIcon
            icon={scrambled ? "mdi:incognito" : "mdi:account-circle"}
          />
          {clientName && (
            <span className="MenuUsuario__nombre_usuario">
              <Scrambler tipo="usuario" propagar={true}>
                {clientName}
              </Scrambler>
            </span>
          )}
        </button>
      </div>
      <PopupMenuUsuario
        visible={mostrarMenu}
        esconder={() => setMostrarMenu(false)}
      />
    </>
  );
};

export default MenuUsuario;
