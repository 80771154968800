import { useQuery } from "@tanstack/react-query";

import { suspensionsApi } from "feedback-api";

type SuspensionCandidateParams = {
  professionalId?: string;
  centerId?: string | null;
  startDate?: string;
  endDate?: string;
  professionalName?: string;
};
const useGetSuspensionCandidatesQuery = ({
  professionalId,
  centerId,
  startDate,
  endDate,
  professionalName,
}: SuspensionCandidateParams) => {
  return useQuery({
    queryKey: [
      "suspensionCandidates",
      professionalId,
      centerId,
      startDate,
      endDate,
    ],
    queryFn: async ({ signal }) => {
      const { data } =
        await suspensionsApi.getCandidatesV4SuspensionsCandidatesGet(
          professionalId!,
          startDate!,
          endDate!,
          professionalName!,
          centerId!,
          { signal },
        );
      return data.candidates;
    },
    enabled: !!professionalId && !!startDate && !!endDate && !!professionalName,
    refetchOnWindowFocus: false,
    retry: false,
  });
};

export default useGetSuspensionCandidatesQuery;
