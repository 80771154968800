import { startOfToday, endOfToday } from "date-fns";

import { CandidateAppointment, SuspensionSettingType } from "feedback-api";

import { CustomMetas } from "../types";
import { ProfessionalOption } from "./NewSuspension";

export interface NewSuspensionContextState {
  startDate: Date;
  endDate: Date;
  suspensionSettingType?: SuspensionSettingType;
  selectedProfessional?: ProfessionalOption;
  selectedAppointments?: CandidateAppointment[];
  excludedCandidateIds?: string[];
  reassignmentDates?: Record<string, Date>;
  customMetas?: CustomMetas;
  serviceId?: number;
}

export const createNewSuspensionContextState =
  (): NewSuspensionContextState => {
    return {
      startDate: startOfToday(),
      endDate: endOfToday(),
    };
  };
