import classNames from "classnames";
import { Resizable } from "re-resizable";
import { ReactNode } from "react";

import "./Drawer.css";

interface DrawerProps {
  open: boolean;
  className?: string;
  resizable?: boolean;
  onBackdropClick?: () => void;
  children?: ReactNode;
}

const Drawer = ({
  open,
  className,
  resizable,
  onBackdropClick,
  children,
}: DrawerProps) => {
  return (
    <div
      className={classNames("Drawer__backdrop", {
        "Drawer__backdrop--open": open,
      })}
      onClick={onBackdropClick}
    >
      {resizable ? (
        <Resizable
          className={classNames(className, "Drawer")}
          enable={{ left: true }}
        >
          {children}
        </Resizable>
      ) : (
        <div className={classNames(className, "Drawer")}>{children}</div>
      )}
    </div>
  );
};

export default Drawer;
