import Linkify from "linkify-react";
import { Fragment } from "react";

interface TextMessageProps {
  message: string;
}

const TextMessage = ({ message }: TextMessageProps) => {
  const messageLines = message.trim().split("\n");
  return (
    <>
      {messageLines.map((line, i) => {
        const boldParts = line.split("*");
        return (
          <Fragment key={i}>
            {boldParts.map((part, j) => (
              <span key={j} style={{ fontWeight: j % 2 === 0 ? 400 : 700 }}>
                <Linkify options={{ target: "_blank" }}>{part}</Linkify>
              </span>
            ))}
            <br />
          </Fragment>
        );
      })}
    </>
  );
};

export default TextMessage;
