import { Icon, InlineIcon } from "@iconify/react";
import {
  CellContext,
  ColumnDef,
  createColumnHelper,
  HeaderContext,
  RowSelectionState,
} from "@tanstack/react-table";
import * as _ from "lodash-es";
import { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import Tooltip from "components/Tooltip";
import Button from "components/atoms/Button";
import Callout from "components/atoms/Callout";
import Checkbox from "components/atoms/Checkbox";
import Loader from "components/atoms/Loader";
import { CandidateAppointment } from "feedback-api";
import { addToast } from "store/slices/toasts";
import { humanizeShortDate } from "utils/date";

import {
  NewSuspensionContext,
  NewSuspensionDispatchContext,
} from "../NewSuspensionContext";
import { getErrorMessageForCode } from "../utils";
import CandidatesTable from "./CandidatesTable";
import ConfigurationCard from "./ConfigurationCard";
import IncompleteAppointmentsModal from "./IncompleteAppointmentsModal";
import ReassignmentConfigurationDrawer from "./ReassignmentConfigurationDrawer";
import "./VerifySuspensionStep.css";

const columnHelper = createColumnHelper<CandidateAppointment>();

const getColumns = (
  [firstHeader, ...otherHeaders]: string[],
  suspensionSettingType?: string,
  reassignmentDates?: Record<string, Date>,
) =>
  [
    {
      id: "select-col",
      header: ({ table }: HeaderContext<CandidateAppointment, string>) => (
        <Checkbox
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      ),
      cell: ({ row }: CellContext<CandidateAppointment, string>) => (
        <Checkbox
          checked={row.getIsSelected()}
          onChange={row.getToggleSelectedHandler()}
          disabled={!row.getCanSelect()}
        />
      ),
    },
    columnHelper.accessor(`appointment_display_data.${firstHeader}`, {
      header: firstHeader,
      cell: (info) =>
        info.row.original.notified ? (
          <Tooltip text="Cita suspendida: este paciente ya fue contactado.">
            <InlineIcon icon="uil:padlock" /> {info.getValue()}
          </Tooltip>
        ) : !info.row.original.has_phone ? (
          <Tooltip text="Paciente incontactable: el número de teléfono proporcionado no es válido.">
            <InlineIcon icon="uil:android-phone-slash" /> {info.getValue()}
          </Tooltip>
        ) : (
          info.getValue()
        ),
      enableSorting: false,
    }),
    ...otherHeaders.map((key) =>
      columnHelper.accessor(`appointment_display_data.${key}`, {
        header: key,
        cell: (info) => info.getValue(),
        enableSorting: false,
      }),
    ),
    ...(suspensionSettingType === "REASSIGNMENT"
      ? [
          columnHelper.display({
            id: "arrowRight",
            cell: () => <InlineIcon icon="uil:arrow-right" />,
          }),
          columnHelper.display({
            header: "Nueva cita",
            cell: (info) => {
              const reassignmentDate = reassignmentDates?.[info.row.id];
              return reassignmentDate && info.row.getIsSelected() ? (
                <span className="VerifySuspensionStep__reassignment_date">
                  {humanizeShortDate(reassignmentDate)}
                </span>
              ) : (
                <span className="VerifySuspensionStep__no_reassignment_date">
                  Cita no informada
                </span>
              );
            },
          }),
        ]
      : []),
  ] as ColumnDef<CandidateAppointment, string>[];

interface VerifyAndSendSuspensionStepProps {
  suspensionCandidates: CandidateAppointment[] | undefined;
  error: Error | null;
  isFetching: boolean;
  onSuccess: () => void;
}

const VerifyAndSendSuspensionStep = ({
  suspensionCandidates,
  error,
  isFetching,
  onSuccess,
}: VerifyAndSendSuspensionStepProps) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [isReassignmentConfigurationOpen, setReassignmentConfigurationOpen] =
    useState(false);
  const {
    selectedProfessional,
    startDate,
    endDate,
    reassignmentDates,
    excludedCandidateIds,
    suspensionSettingType,
  } = useContext(NewSuspensionContext);

  if (!selectedProfessional) {
    throw new Error("No professional selected");
  }
  const newSuspensionDispatch = useContext(NewSuspensionDispatchContext);
  const dispatch = useDispatch();

  const selectableCandidates = useMemo(
    () =>
      suspensionCandidates?.filter(
        (candidate) => !candidate.notified && candidate.has_phone,
      ),
    [suspensionCandidates],
  );

  const selectedCandidates = useMemo(
    () =>
      selectableCandidates?.filter((candidate) => rowSelection[candidate.id]),
    [selectableCandidates, rowSelection],
  );
  const selectedCandidatesCount = selectedCandidates?.length ?? 0;
  const reassignmentsCount = useMemo(
    () =>
      Object.keys(reassignmentDates ?? {}).filter(
        (appointmentId) => rowSelection[appointmentId],
      ).length,
    [rowSelection, reassignmentDates],
  );

  useEffect(() => {
    const exclusionSet = new Set(excludedCandidateIds);
    setRowSelection(
      selectableCandidates
        ? Object.fromEntries(
            selectableCandidates.map((candidate) => [
              candidate.id,
              !exclusionSet.has(candidate.id),
            ]),
          )
        : {},
    );
  }, [selectableCandidates, excludedCandidateIds]);

  const copyDetail = useMemo(() => {
    switch (suspensionSettingType) {
      case "REASSIGNMENT":
        return "En esta etapa, puedes desmarcar a los pacientes que no desees incluir en el envío de mensajes e incluir la información de nuevas citas para notificar a los pacientes que consideres necesario.";
      default:
        return "En esta etapa, puedes desmarcar a los pacientes que no desees incluir en el envío de mensajes.";
    }
  }, [suspensionSettingType]);

  const pluralize = (words: string[], count: number) => {
    return words.map((word) => word + (count === 1 ? "" : "s")).join(" ");
  };
  const footerSuffix = ` (${selectedCandidatesCount} ${pluralize(["paciente", "seleccionado"], selectedCandidatesCount)})`;

  const checkSelection = () => {
    if (![0, selectedCandidatesCount].includes(reassignmentsCount)) {
      setModalOpen(true);
      return;
    }
    saveAndContinue();
  };

  const saveAndContinue = () => {
    if (!selectedCandidates) {
      dispatch(
        addToast({
          type: "error",
          message: "Hubo un problema con el sistema, reintenta más tarde.",
        }),
      );
      return;
    }

    newSuspensionDispatch({
      type: "SET_SUSPENSION_APPOINTMENTS",
      payload: {
        excludedCandidateIds: _.difference(
          (selectableCandidates ?? []).map((c) => c.id),
          (selectedCandidates ?? []).map((c) => c.id),
        ),
        selectedAppointments: selectedCandidates,
      },
    });
    onSuccess();
  };

  return (
    <div className="UploadSpreadsheetStep">
      <div className="UploadSpreadsheetStep__section">
        <h3 className="UploadSpreadsheetStep__title">Verificar pacientes</h3>
        <p className="UploadSpreadsheetStep__copy">
          Revisa el listado de pacientes de acuerdo a los criterios definidos en
          el paso anterior. {copyDetail}
        </p>
      </div>
      <ConfigurationCard
        startDate={startDate}
        endDate={endDate}
        professionalName={selectedProfessional.label}
      />
      {suspensionSettingType === "REASSIGNMENT" && (
        <div className="VerifySuspensionStep__reassignment">
          <Button
            variant="outline"
            onClick={() => setReassignmentConfigurationOpen(true)}
            icon={<Icon icon="uil:calender" />}
            disabled={isFetching}
          >
            Notificación de nuevas citas
          </Button>
          <div className="VerifySuspensionStep__reassignment_counter">
            {reassignmentsCount}/{selectedCandidatesCount}{" "}
            <span className="VerifySuspensionStep__reassignment_count">
              {pluralize(["nueva", "cita"], selectedCandidatesCount)} a
              notificar
            </span>
          </div>
          <ReassignmentConfigurationDrawer
            open={isReassignmentConfigurationOpen}
            setOpen={setReassignmentConfigurationOpen}
            suspensionCandidates={suspensionCandidates}
            rowSelection={rowSelection}
          />
        </div>
      )}
      <div className="VerifySuspensionStep__table_wrapper">
        {error ? (
          <Callout variant="danger">{getErrorMessageForCode(error)}</Callout>
        ) : suspensionCandidates?.length === 0 ? (
          <Callout variant="danger">
            No se encontraron citas para suspender.
          </Callout>
        ) : (
          <>
            <CandidatesTable
              appointments={suspensionCandidates}
              columns={
                suspensionCandidates?.length
                  ? getColumns(
                      Object.keys(
                        suspensionCandidates[0].appointment_display_data,
                      ),
                      suspensionSettingType,
                      reassignmentDates,
                    )
                  : []
              }
              footerSuffix={footerSuffix}
              rowSelection={rowSelection}
              onRowSelectionChange={setRowSelection}
              enableRowSelection={(row) =>
                !row.original.notified && row.original.has_phone
              }
              getRowId={(row) => row.id}
            />
            {isFetching && (
              <div className="VerifySuspensionStep__loading_popup">
                <Loader size="3rem" />
                <div className="VerifySuspensionStep__loading_popup__content">
                  <div className="VerifySuspensionStep__loading_popup__title">
                    Estamos cargando los pacientes desde tu agenda
                  </div>
                  <div className="VerifySuspensionStep__loading_popup__detail">
                    El proceso podría tardar hasta unos{" "}
                    <span className="VerifySuspensionStep__loading_popup__highlight">
                      5 minutos
                    </span>
                    .
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <Button onClick={checkSelection} disabled={selectedCandidatesCount === 0}>
        Configurar mensajes
      </Button>
      <IncompleteAppointmentsModal
        isOpen={isModalOpen}
        selectedCandidatesCount={selectedCandidatesCount}
        reassignmentsCount={reassignmentsCount}
        onConfirm={saveAndContinue}
        onCancel={() => setModalOpen(false)}
      />
    </div>
  );
};

export default VerifyAndSendSuspensionStep;
