import classNames from "classnames";
import { parseISO } from "date-fns";
import { useRef, useEffect } from "react";
import { useMatch } from "react-router";

import { ChatAPIConversation } from "api/types/responses";
import useAnalytics from "hooks/useAnalytics";

import BarraAppCelular from "./BarraAppCelular";
import BarraEstadoCelular from "./BarraEstadoCelular";
import "./CelularWhatsapp.css";
import LoaderMensajes from "./LoaderMensajes";
import MensajeSinWhatsapp from "./MensajeSinWhatsapp";
import MensajeWhatsapp from "./MensajeWhatsapp";

interface CelularWhatsappProps {
  conversaciones: ChatAPIConversation[] | undefined;
  indiceConversacion: number | undefined;
  seleccionarConversacion: (i: number) => void;
  nombrePaciente: string | undefined;
  telefono: string;
  intentos:
    | {
        phone: string;
        timestamp: string;
      }[]
    | undefined;
}

const CelularWhatsapp = ({
  conversaciones,
  indiceConversacion,
  seleccionarConversacion,
  nombrePaciente,
  telefono,
  intentos,
}: CelularWhatsappProps) => {
  const conversacionMasReciente = useRef<HTMLDivElement>(null);
  const match = useMatch("respuestas/:serviceId/:patientId");
  const track = useAnalytics();

  useEffect(() => {
    conversacionMasReciente.current?.scrollIntoView();
  }, [conversaciones]);

  return (
    <div className="CelularWhatsapp">
      <div className="CelularWhatsapp__celular">
        <div className="CelularWhatsapp__datos_extra">
          {match?.params.serviceId}/{match?.params.patientId}
        </div>
        <div className="CelularWhatsapp__pantalla">
          <BarraEstadoCelular />
          <BarraAppCelular
            nombrePaciente={nombrePaciente}
            telefono={telefono}
          />
          <div className="CelularWhatsapp__contenedor_mensajes" tabIndex={0}>
            {conversaciones ? (
              conversaciones.map((c, ic) => {
                const mensajes = c.messages;
                const start_offset = parseISO(c.start);
                return (
                  <div
                    key={`contenedor-conversacion-${ic}`}
                    ref={
                      ic === indiceConversacion
                        ? conversacionMasReciente
                        : undefined
                    }
                    className={classNames({
                      CelularWhatsapp__contenedor_conversacion: true,
                      "CelularWhatsapp__contenedor_conversacion--seleccionada":
                        ic === indiceConversacion,
                    })}
                    title={ic === indiceConversacion ? "" : "Ver conversación"}
                    onClick={() => {
                      if (ic !== indiceConversacion) {
                        track("Feedback", "Chat", "clickEnOtraConversacion");
                        seleccionarConversacion(c.id);
                      }
                    }}
                  >
                    {c.is_unreachable?.whatsapp && c.is_unreachable?.phone ? (
                      <MensajeSinWhatsapp
                        start={start_offset}
                        intentos={intentos}
                      />
                    ) : mensajes.length > 0 ? (
                      mensajes.map((mensaje, i) => (
                        <MensajeWhatsapp
                          mensaje={mensaje}
                          mensajes={mensajes}
                          posicion={i}
                          key={`mensaje-${i}`}
                        />
                      ))
                    ) : (
                      <p className="CelularWhatsapp__conversacion_vacia">
                        Esta conversación todavía no contiene mensajes
                      </p>
                    )}
                  </div>
                );
              })
            ) : (
              <LoaderMensajes />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CelularWhatsapp;
