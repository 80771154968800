import Loader from "components/atoms/Loader";
import useAnalytics from "hooks/useAnalytics";

import "./SmartphoneActionBar.css";

interface SmartphoneActionBarProps {
  phone?: string;
  contactName?: string;
}

const SmartphoneActionBar = ({
  phone,
  contactName,
}: SmartphoneActionBarProps) => {
  const track = useAnalytics();

  const copyContactName = () => {
    if (!contactName) {
      return;
    }
    navigator.clipboard.writeText(contactName);
    track("Feedback", "Smartphone", "copy", {
      property: "patientName",
      value: contactName,
    });
  };

  const copyPhone = () => {
    if (!phone) {
      return;
    }
    navigator.clipboard.writeText(phone + "");
    track("Feedback", "Smartphone", "copy", {
      property: "phone",
      value: phone,
    });
  };

  const avatarHue =
    360 * (((contactName?.[0]?.toLowerCase() ?? "a").charCodeAt(0) - 97) / 25);

  return (
    <div className="SmartphoneActionBar">
      <div
        className="SmartphoneActionBar__avatar"
        style={
          {
            "--avatar-hue": avatarHue,
          } as React.CSSProperties
        }
      >
        {contactName?.[0] || <Loader color="#fff" />}
      </div>
      <div
        className="SmartphoneActionBar__receiver_name"
        onClick={copyContactName}
      >
        {contactName}
        <button
          className="SmartphoneActionBar__copy_button"
          title={`Copiar "${contactName}"`}
        >
          Copiar
        </button>
      </div>
      <div
        className="SmartphoneActionBar__receiver_status"
        onClick={copyPhone}
        title={`Copiar "${phone}"`}
      >
        <span className="SmartphoneActionBar__receiver_phone">
          {phone && <>+{phone}</>}
        </span>
        <button
          className="SmartphoneActionBar__copy_button"
          title={`Copiar "${phone}"`}
        >
          Copiar
        </button>
      </div>
      <div className="SmartphoneActionBar__actions"></div>
    </div>
  );
};

export default SmartphoneActionBar;
