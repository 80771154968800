import { CSSProperties, PropsWithChildren, useRef, useState } from "react";
import ReactDOM from "react-dom";

import "./Tooltip.css";

interface TooltipProps {
  text: string;
  disabled?: boolean;
}

const Tooltip = ({
  children,
  text,
  disabled = false,
}: PropsWithChildren<TooltipProps>) => {
  const [containerStyle, setContainerStyle] = useState<CSSProperties>({
    top: 0,
    left: 0,
    opacity: 0,
  });
  const wrapperRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const lines = text.split("\n");
  const handleHover = () => {
    if (!wrapperRef.current || !containerRef.current) {
      return;
    }
    const wrapperRect = wrapperRef.current.getBoundingClientRect();
    const containerRect = containerRef.current.getBoundingClientRect();
    setContainerStyle({
      top: window.scrollY + wrapperRect.top + wrapperRect.height,
      left:
        window.scrollX +
        wrapperRect.left +
        (wrapperRect.width - containerRect.width) / 2,
      opacity: 1,
    });
  };
  const handleHoverOut = () => {
    setContainerStyle({ ...containerStyle, opacity: 0 });
  };
  return (
    <div ref={wrapperRef} onMouseOver={handleHover} onMouseOut={handleHoverOut}>
      {children}
      {!disabled &&
        ReactDOM.createPortal(
          <div
            ref={containerRef}
            className="Tooltip__container"
            style={containerStyle}
          >
            <span className="Tooltip__text">
              {lines.map((line, i) => (
                <p key={i}>{line}</p>
              ))}
            </span>
          </div>,
          document.body,
        )}
    </div>
  );
};

export default Tooltip;
