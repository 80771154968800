import { useQuery } from "@tanstack/react-query";

import { suspensionsApi } from "feedback-api";

const useGetSuspensionSettingsQuery = () => {
  return useQuery({
    queryKey: ["suspensionSettings"],
    queryFn: async ({ signal }) => {
      const { data } =
        await suspensionsApi.getSuspensionSettingsV4SuspensionsSuspensionSettingsGet(
          { signal },
        );
      return data.suspension_settings;
    },
  });
};

export default useGetSuspensionSettingsQuery;
