import { InlineIcon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router";

import { esCero } from "helpers/permisos";
import { ceroSelector } from "store/slices/cero";
import { encuestasSelector } from "store/slices/encuestas";
import { activaEnviador } from "store/slices/enviador";
import { loginSelector } from "store/slices/login";

import EnviadorRespuestas from "./EnviadorRespuestas";
import "./Respuestas.css";

export const BotonEnviador = () => {
  const { debugging } = useSelector(ceroSelector);
  const { cuenta } = useSelector(loginSelector);

  const dispatch = useDispatch();

  return (
    esCero(cuenta) &&
    debugging && (
      <button
        className="Topbar__boton_enviador"
        onClick={() => dispatch(activaEnviador())}
      >
        <InlineIcon icon="mdi:send" /> Contactar pacientes
      </button>
    )
  );
};

const Respuestas = () => {
  const { idEncuestaSeleccionada: idEncuesta } = useSelector(encuestasSelector);

  return (
    <div className="Respuestas">
      {idEncuesta && <EnviadorRespuestas idEncuesta={idEncuesta} />}
      <Outlet />
    </div>
  );
};

export default Respuestas;
