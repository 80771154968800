import { useId } from "react";

import Switch from "components/atoms/Switch";

import "./SwitchGroup.css";

interface SwitchGroupProps {
  label: string;
  description?: string;
  value?: boolean;
  disabled?: boolean;
  onChange?: (value: boolean) => void;
  onFocus?: () => void;
}

const SwitchGroup = ({
  label,
  description,
  value,
  disabled,
  onChange,
  onFocus,
}: SwitchGroupProps) => {
  const htmlId = useId();

  return (
    <div className="SwitchGroup">
      <div className="SwitchGroup__context">
        <label className="SwitchGroup__label" htmlFor={htmlId}>
          {label}
        </label>
        {description && (
          <p className="SwitchGroup__description">{description}</p>
        )}
      </div>
      <Switch
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        id={htmlId}
        disabled={disabled}
      />
    </div>
  );
};

export default SwitchGroup;
