import { Fragment, useContext, useEffect } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";

import Button from "components/atoms/Button";
import InputGroup from "components/molecules/InputGroup/InputGroup";
import SelectGroup from "components/molecules/SelectGroup";
import SwitchGroup from "components/molecules/SwitchGroup/SwitchGroup";
import TextAreaGroup from "components/molecules/TextAreaGroup/TextAreaGroup";
import { SelectOption } from "feedback-api";
import { GetConfigFieldsResponse } from "feedback-api/generated/api";

import { CustomMetas } from "../../types";
import { NewSuspensionDispatchContext } from "../NewSuspensionContext";
import "../VerifySuspensionStep/VerifySuspensionStep.css";

interface ConfigureSuspensionMessagesFormProps {
  configFields: GetConfigFieldsResponse;
  onSubmit: () => void;
  setSelectedMessageId: (messageId: string) => void;
  setSuspensionMetas: (metas: {
    [k: string]: string | number | boolean | undefined | object;
  }) => void;
}

const ConfigureSuspensionMessagesForm = ({
  configFields,
  onSubmit,
  setSelectedMessageId,
  setSuspensionMetas,
}: ConfigureSuspensionMessagesFormProps) => {
  const defaultValues = Object.fromEntries(
    [...configFields.standard_fields, ...configFields.advanced_fields].map(
      (field) => [field.meta_name, field.default_value],
    ),
  );
  const newSuspensionDispatch = useContext(NewSuspensionDispatchContext);

  const { control, handleSubmit } = useForm({
    defaultValues,
    shouldUnregister: true,
  });

  const suspensionMetas = useWatch({ control });
  useEffect(() => {
    setSuspensionMetas(suspensionMetas);
  }, [suspensionMetas, setSuspensionMetas]);

  const submitForm = (customMetas: CustomMetas) => {
    newSuspensionDispatch({
      type: "SET_CUSTOM_METAS",
      payload: customMetas,
    });
    onSubmit();
  };

  return (
    <form
      className="ConfigureCampaignStep__form"
      onSubmit={handleSubmit(submitForm)}
    >
      {configFields?.standard_fields.map((configField) => (
        <Fragment key={configField.meta_name}>
          {configField.type === "switch" ? (
            <Controller
              name={configField.meta_name}
              control={control}
              render={({ field }) => (
                <SwitchGroup
                  label={configField.display_name}
                  description={configField.description}
                  value={field.value as boolean}
                  onChange={field.onChange}
                  onFocus={() =>
                    configField.message_id &&
                    setSelectedMessageId(configField.message_id)
                  }
                />
              )}
            />
          ) : configField.type === "textarea" ? (
            <Controller
              key={configField.meta_name}
              name={configField.meta_name}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextAreaGroup
                  label={configField.display_name}
                  description={configField.description}
                  rows={4}
                  placeholder="Ingresa tu mensaje personalizado"
                  ref={field.ref}
                  value={field.value as string}
                  onChange={field.onChange}
                  onFocus={() =>
                    configField.message_id &&
                    setSelectedMessageId(configField.message_id)
                  }
                  error={error?.message}
                />
              )}
              rules={{
                validate: {
                  nonBlank: (v) =>
                    // @ts-expect-error: v will always be string | undefined
                    v?.trim() !== "" ||
                    "Agrega un nuevo texto para este mensaje, en caso contrario desactiva la personalización.",
                },
              }}
            />
          ) : configField.type === "select" ? (
            <Controller
              key={configField.meta_name}
              name={configField.meta_name}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <SelectGroup
                  label={configField.display_name}
                  description={configField.description}
                  placeholder="Selecciona una opción"
                  error={error?.message}
                  options={configField.options}
                  onChange={(newValue) =>
                    field.onChange((newValue as SelectOption).value)
                  }
                  value={configField.options.find(
                    (option) => option.value === field.value,
                  )}
                  menuPortalTarget={document.body}
                />
              )}
              rules={{
                required: "Selecciona alguna de las opciones.",
              }}
            />
          ) : (
            <Controller
              key={configField.meta_name}
              name={configField.meta_name}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <InputGroup
                  label={configField.display_name}
                  description={configField.description}
                  type={configField.type}
                  placeholder="Ingresa tu mensaje personalizado"
                  ref={field.ref}
                  value={field.value as string}
                  onChange={field.onChange}
                  onFocus={() =>
                    configField.message_id &&
                    setSelectedMessageId(configField.message_id)
                  }
                  error={error?.message}
                  collapsable
                />
              )}
              rules={{
                validate: {
                  nonBlank: (v) =>
                    // @ts-expect-error: v will always be string | undefined
                    v?.trim() !== "" ||
                    "Agrega un nuevo texto para este mensaje, en caso contrario desactiva la personalización.",
                },
              }}
            />
          )}
        </Fragment>
      ))}
      <Button type="submit" formNoValidate>
        Crear suspensión
      </Button>
    </form>
  );
};

export default ConfigureSuspensionMessagesForm;
