// TODO: move to ../../hooks/
import { useSelector } from "react-redux";

import { loginSelector } from "store/slices/login";

const forbiddenClients = ["Sanasalud", "Norden"];

function useCanSeeUnreachables(): boolean {
  const { clientName } = useSelector(loginSelector);
  return !!clientName && !forbiddenClients.includes(clientName);
}

export default useCanSeeUnreachables;
