import { useQuery } from "@tanstack/react-query";
import {
  addDays,
  addHours,
  format,
  isToday,
  isYesterday,
  parseISO,
} from "date-fns";
import { es } from "date-fns/locale";
import * as _ from "lodash-es";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router";

import logo from "assets/images/logo_cuadrado_notificaciones.png";
import useAnalytics from "hooks/useAnalytics";
import { alertsSelector } from "store/slices/alerts";

import { Alert } from "../types/domain";
import { AlertsAPIResponse } from "../types/responses";
import useAlertTypesQuery from "./useAlertTypesQuery";
import useBranchesQuery from "./useBranchesQuery";
import useServicesQuery from "./useServicesQuery";
import { get, API_ROOT } from "./utils";

const alertsSinceDays = 3;

export interface ActiveAlerts {
  pending: Alert[];
  solved: Alert[];
}

const useActiveAlertsQuery = () => {
  const {
    hiddenAlertTypes,
    hiddenBranches,
    hiddenServices,
    notificationsEnabled,
  } = useSelector(alertsSelector);
  const { data: alertTypes } = useAlertTypesQuery();
  const { data: services } = useServicesQuery();
  const { data: branches } = useBranchesQuery();
  const navigate = useNavigate();
  const track = useAnalytics();

  const isAlertActive = useCallback(
    (alert: Alert) => {
      return (
        !_.includes(hiddenAlertTypes, alert.typeId) &&
        !_.includes(hiddenBranches, alert.branchId) &&
        !_.includes(hiddenServices, alert.serviceId) &&
        alertTypes?.some((t) => t.id === alert.typeId)
      );
    },
    [hiddenAlertTypes, hiddenBranches, hiddenServices, alertTypes],
  );

  const today = format(new Date(), "yyyy-MM-dd");
  const daysAgo = format(addDays(new Date(), -alertsSinceDays), "yyyy-MM-dd");
  const url = `${API_ROOT}/polls/alerts?start_date=${daysAgo}&end_date=${today}`;

  return useQuery<Alert[], unknown, ActiveAlerts>({
    queryKey: ["alerts"],
    queryFn: async ({ signal }) => {
      const { data } = await get<AlertsAPIResponse>(url, { signal });
      const allAlerts = data.data.map((alert): Alert => {
        const typeName = alertTypes?.find((t) => t.id === alert.message)?.name;
        const serviceName = services?.find((t) => t.id === alert.poll_id)?.name;
        const patientName = alert.patient_name;
        const branchId = alert.branch_name;
        const branchName = branches?.find((b) => b.id === branchId)?.name;
        const horasMenos = new Date().getTimezoneOffset() / 60;
        const timestamp = addHours(parseISO(alert.utc_timestamp), -horasMenos);
        return {
          id: alert.id,
          typeId: alert.message,
          typeName,
          solved: alert.dismissed,
          solvedBy: alert.dismissal_by_username,
          timestamp,
          formattedTimestamp: formatAlertTimestamp(timestamp),
          serviceId: alert.poll_id,
          serviceName,
          patientId: alert.user_id,
          patientName,
          branchId,
          branchName,
        };
      });
      const alertsPerPatient = _.groupBy(allAlerts, (alert) => [
        alert.patientId,
        alert.serviceId,
      ]);
      const onlyLatestAlertPerPatient = _.values(alertsPerPatient).map(
        (arr) => arr.slice(-1)[0],
      );
      return onlyLatestAlertPerPatient;
    },
    refetchInterval: 5_000,
    refetchIntervalInBackground: true,
    enabled: !!alertTypes && !!services,
    select: (data) => {
      const activeAlerts = _.reverse(
        _.sortBy(data.filter(isAlertActive), "timestamp"),
      );
      return {
        pending: activeAlerts.filter((a) => !a.solved),
        solved: activeAlerts.filter((a) => a.solved),
      };
    },
    // TODO: maybe this notifications side effect does not belong in this hook
    structuralSharing: (oldData, newData) => {
      if (Array.isArray(newData)) {
        return newData as Alert[];
      }
      const newActiveAlerts = (
        newData as ActiveAlerts | undefined
      )?.pending.filter((alert) => isAlertActive(alert) && !alert.solved);
      const oldActiveAlerts = (
        oldData as ActiveAlerts | undefined
      )?.pending.filter((alert) => isAlertActive(alert) && !alert.solved);
      const newAlerts = _.differenceBy(
        newActiveAlerts,
        oldActiveAlerts || [],
        (x) => x.id,
      );
      if (oldActiveAlerts && newAlerts.length > 0 && notificationsEnabled) {
        emitNotification(navigate, newAlerts[0], track);
      }
      return newData;
    },
  });
};

const formatAlertTimestamp = (timestamp: Date): string => {
  if (isToday(timestamp)) {
    return "hoy, " + format(timestamp, "HH:mm");
  }
  if (isYesterday(timestamp)) {
    return "ayer, " + format(timestamp, "HH:mm");
  }
  return format(timestamp, "iiii dd", { locale: es });
};

const emitNotification = (
  navigate: NavigateFunction,
  alert: Alert,
  track: ReturnType<typeof useAnalytics>,
) => {
  const notification = new Notification("Feedback", {
    body: alert.typeName,
    requireInteraction: true,
    silent: false,
    icon: logo,
  });
  notification.onclick = () => {
    navigate(`/alertas/${alert.serviceId}/${alert.patientId}`);
    track("Feedback", "Alerts", "notificationClick");
    window.focus();
  };
};

export default useActiveAlertsQuery;
