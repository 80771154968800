import { Icon, InlineIcon } from "@iconify/react";
import {
  ColumnDef,
  createColumnHelper,
  RowSelectionState,
} from "@tanstack/react-table";
import classNames from "classnames";
import { formatISO } from "date-fns";
import { es } from "date-fns/locale";
import { useContext, useEffect, useMemo } from "react";
import {
  Control,
  Controller,
  SubmitHandler,
  useForm,
  UseFormResetField,
} from "react-hook-form";

import DatePicker from "components/DatePicker";
import Button from "components/atoms/Button";
import Callout from "components/atoms/Callout";
import Drawer from "components/atoms/Drawer";
import { CandidateAppointment } from "feedback-api";

import {
  NewSuspensionContext,
  NewSuspensionDispatchContext,
} from "../../NewSuspensionContext";
import "./ReassignmentConfigurationDrawer.css";
import ReassignmentTable from "./ReassignmentTable";

interface ReassignmentConfigurationDrawerProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  suspensionCandidates?: CandidateAppointment[];
  rowSelection: RowSelectionState;
}

const columnHelper = createColumnHelper<CandidateAppointment>();

const getColumns = (
  [firstHeader, ...otherHeaders]: string[],
  control: Control<Record<string, Date | null | undefined>>,
  resetField: UseFormResetField<Record<string, Date | null | undefined>>,
) =>
  [
    columnHelper.accessor(`appointment_display_data.${firstHeader}`, {
      header: firstHeader,
      cell: (info) => {
        const isSelected = info.row.getIsSelected();
        return (
          <div className="ReassignmentConfigurationDrawer__first_row_cell">
            {!isSelected && (
              <InlineIcon icon="uil:ban" fontSize="1rem" color="#6A678e" />
            )}
            {info.getValue()}
          </div>
        );
      },
      enableSorting: false,
    }),
    ...otherHeaders.map((key) =>
      columnHelper.accessor(`appointment_display_data.${key}`, {
        header: key,
        cell: (info) => info.getValue(),
        enableSorting: false,
      }),
    ),
    columnHelper.display({
      id: "arrowRight",
      cell: () => (
        <InlineIcon
          className="ReassignmentConfigurationDrawer__arrow"
          icon="uil:arrow-right"
        />
      ),
    }),
    columnHelper.display({
      header: "Nueva fecha",
      cell: (info) => (
        <Controller
          name={`date_${btoa(info.row.id)}`}
          control={control}
          render={({ field: { value, ...field }, fieldState: { error } }) => (
            <DatePicker
              wrapperClassName={classNames(
                "ReassignmentConfigurationDrawer__datepicker_wrapper",
                {
                  "ReassignmentConfigurationDrawer__datepicker_wrapper--filled":
                    value,
                  "ReassignmentConfigurationDrawer__datepicker_wrapper--invalid":
                    error,
                },
              )}
              className="ReassignmentConfigurationDrawer__datepicker"
              {...field}
              selected={value}
              locale={es}
              dateFormat="P"
              placeholderText="––/––/––––"
              showIcon
              icon={
                <Icon
                  className="ReassignmentConfigurationDrawer__datepicker_icon"
                  width="1rem"
                  icon="uil:calendar-alt"
                />
              }
              disabled={!info.row.getIsSelected()}
              required={!!error}
            />
          )}
          rules={{
            validate: (value, formValues) =>
              !formValues[`time_${btoa(info.row.id)}`] || !!value,
          }}
        />
      ),
    }),
    columnHelper.display({
      header: "Nueva hora",
      cell: (info) => (
        <Controller
          name={`time_${btoa(info.row.id)}`}
          control={control}
          render={({ field: { value, ...field }, fieldState: { error } }) => (
            <div style={{ display: "flex", gap: "1.75rem" }}>
              <DatePicker
                wrapperClassName={classNames(
                  "ReassignmentConfigurationDrawer__datepicker_wrapper",
                  {
                    "ReassignmentConfigurationDrawer__datepicker_wrapper--filled":
                      value,
                    "ReassignmentConfigurationDrawer__datepicker_wrapper--invalid":
                      error,
                  },
                )}
                className="ReassignmentConfigurationDrawer__datepicker"
                {...field}
                selected={value}
                locale={es}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={5}
                timeCaption="Hora"
                dateFormat="p"
                placeholderText="––:––"
                showIcon
                icon={
                  <Icon
                    className="ReassignmentConfigurationDrawer__datepicker_icon"
                    icon="uil:clock"
                  />
                }
                disabled={!info.row.getIsSelected()}
                required={!!error}
              />
              <button
                className="ReassignmentConfigurationDrawer__reset"
                type="button"
                title="Descartar notificación de nueva cita"
                disabled={!info.row.getIsSelected()}
                onClick={() => {
                  resetField(`date_${btoa(info.row.id)}`, {
                    defaultValue: null,
                  });
                  resetField(`time_${btoa(info.row.id)}`, {
                    defaultValue: null,
                  });
                }}
              >
                <Icon icon="uil:calendar-slash" />
              </button>
            </div>
          )}
          rules={{
            validate: (value, formValues) =>
              !formValues[`date_${btoa(info.row.id)}`] || !!value,
          }}
        />
      ),
    }),
  ] as ColumnDef<CandidateAppointment, string>[];

const ReassignmentConfigurationDrawer = ({
  open,
  setOpen,
  suspensionCandidates,
  rowSelection,
}: ReassignmentConfigurationDrawerProps) => {
  const { reassignmentDates } = useContext(NewSuspensionContext);
  const newSuspensionDispatch = useContext(NewSuspensionDispatchContext);

  const {
    control,
    handleSubmit,
    reset,
    resetField,
    formState: { errors },
  } = useForm({ shouldUnregister: true });

  useEffect(() => {
    if (open) {
      reset(
        Object.fromEntries(
          Object.entries(reassignmentDates ?? {})
            .map(([id, reassignmentDate]) => [
              [`date_${btoa(id)}`, reassignmentDate],
              [`time_${btoa(id)}`, reassignmentDate],
            ])
            .flat(),
        ),
      );
    }
  }, [open, reassignmentDates, reset]);

  const columns = useMemo(
    () =>
      suspensionCandidates?.length
        ? getColumns(
            Object.keys(suspensionCandidates[0].appointment_display_data),
            control,
            resetField,
          )
        : [],
    [control, suspensionCandidates, resetField],
  );

  const onSubmit: SubmitHandler<Record<string, string>> = (data) => {
    const newReassignmentDates: Record<string, Date> = {};

    Object.entries(data).forEach(([dateKey, date]) => {
      if (!dateKey.startsWith("date_") || !date) {
        return;
      }
      const id = atob(dateKey.slice(5));
      const timeKey = `time_${btoa(id)}`;
      const time = data[timeKey];
      if (!time) {
        return;
      }
      newReassignmentDates[id] = new Date(
        `${formatISO(date).slice(0, 10)}T${formatISO(time).slice(11, 16)}`,
      );
    });

    newSuspensionDispatch({
      type: "SET_REASSIGNMENT_DATES",
      payload: newReassignmentDates,
    });
    setOpen(false);
  };

  return (
    <Drawer open={open}>
      <form
        className="ReassignmentConfigurationDrawer"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="ReassignmentConfigurationDrawer__scrollarea">
          <header className="ReassignmentConfigurationDrawer__header">
            <h2 className="ReassignmentConfigurationDrawer__title">
              Notificación de nuevas citas
            </h2>
            <button
              type="button"
              onClick={() => setOpen(false)}
              className="GeneralConfigurationDrawer__close_button"
            >
              <Icon icon="uil:times" />
            </button>
          </header>
          <main className="ReassignmentConfigurationDrawer__body">
            <p className="ReassignmentConfigurationDrawer__description">
              Indica las fechas y horas de las nuevas citas para que podamos
              notificar a los pacientes de los cambios.
            </p>
            {!!Object.keys(errors).length && (
              <Callout variant="danger">
                Hay citas incompletas. Por favor, revisa y completa la
                información de los pacientes para continuar.
              </Callout>
            )}
            <ReassignmentTable
              appointments={suspensionCandidates}
              columns={columns}
              rowSelection={rowSelection}
              getRowId={(row) => row.id}
            />
          </main>
        </div>
        <div className="ReassignmentConfigurationDrawer__actions">
          <Button variant="outline" onClick={() => setOpen(false)}>
            Cancelar
          </Button>
          <Button type="submit">Guardar cambios</Button>
        </div>
      </form>
    </Drawer>
  );
};

export default ReassignmentConfigurationDrawer;
