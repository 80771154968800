import { Outlet } from "react-router";

import "./Waitlists.css";

export const WaitlistsTopbar = () => (
  <h2 className="Waitlists__title">Listas de Espera</h2>
);

const Waitlists = () => {
  return (
    <div className="Waitlists">
      <Outlet />
    </div>
  );
};

export default Waitlists;
