import store from "store";

import { Configuration } from "./generated";

export class NoTokenError extends Error {
  constructor(message?: string, options?: ErrorOptions) {
    super(message, options);
    this.name = "NoTokenError";
  }
}

const configuration = new Configuration({
  basePath: import.meta.env.VITE_API_ROOT,
  accessToken: () => {
    const token = store.getState().login.token;
    if (!token) {
      throw new NoTokenError();
    }
    return token;
  },
});

export default configuration;
