import { Icon } from "@iconify/react";
import { useState } from "react";
import { useNavigate } from "react-router";

import { InteractionId } from "api/types/domain";
import logo from "assets/images/logo_blanco.png";
import Button from "components/atoms/Button";
import useAnalytics from "hooks/useAnalytics";
import useIsLabeler from "hooks/useIsLabeler";
import useWhatsappLink from "hooks/useWhatsappLink";

import { openExternalLink } from "../helpers";
import "./InteractionDrawerActions.css";
import ReportIssueDialog from "./IssueSubmissionDialog";

interface InteractionDrawerActionsProps {
  interactionId?: InteractionId;
  phone?: string;
  schedulingSystemName?: string;
  schedulingSystemURL?: string;
  originComponentName: string;
}

const InteractionDrawerActions = ({
  interactionId,
  phone,
  schedulingSystemName,
  schedulingSystemURL,
  originComponentName,
}: InteractionDrawerActionsProps) => {
  const navigate = useNavigate();
  const track = useAnalytics();
  const isLabeler = useIsLabeler();
  const [issueDialogVisible, setIssueDialogVisible] = useState(false);
  const whatsappLink = useWhatsappLink(phone);

  const openChatView = () => {
    navigate(
      `/respuestas/${interactionId?.serviceId}/${interactionId?.patientId}`,
      {
        state: {
          from: originComponentName === "Search" ? "/busqueda" : "/alertas",
        },
      },
    );
  };

  const openWhatsapp = () => openExternalLink(whatsappLink);

  const openSchedulingSystem = () => {
    if (!schedulingSystemURL) {
      return;
    }
    openExternalLink(schedulingSystemURL);
  };

  return (
    <div className="InteractionDrawerActions">
      <ReportIssueDialog
        interactionId={interactionId}
        visible={issueDialogVisible}
        onClose={() => setIssueDialogVisible(false)}
        originComponentName={originComponentName}
      />
      <Button
        size="small"
        onClick={() => {
          openChatView();
          track("Feedback", originComponentName, "openChatView");
        }}
        icon={<Icon icon="mdi:cellphone" />}
      >
        Ver en vista Chat
      </Button>
      {!isLabeler && (
        <Button
          size="small"
          onClick={() => {
            track("Feedback", originComponentName, "openWhatsapp");
            openWhatsapp();
          }}
          icon={<Icon icon="mdi:whatsapp" />}
        >
          Contactar por Whatsapp
        </Button>
      )}
      {schedulingSystemURL && !isLabeler && (
        <Button
          size="small"
          onClick={() => {
            track("Feedback", originComponentName, "openSchedulingSystem");
            openSchedulingSystem();
          }}
          icon={<Icon icon="mdi:arrow-top-right" />}
        >
          Ver cita en {schedulingSystemName}
        </Button>
      )}
      {!isLabeler && (
        <Button
          size="small"
          onClick={() => {
            track("Feedback", originComponentName, "openReportIssueDialog");
            setIssueDialogVisible(true);
          }}
          icon={<Icon icon="mdi:alert" />}
        >
          Reportar problema a{" "}
          <img
            className="InteractionDrawerActions__cero"
            src={logo}
            alt="CERO"
          />
        </Button>
      )}
    </div>
  );
};

export default InteractionDrawerActions;
