import { useQuery } from "@tanstack/react-query";
import { HttpStatusCode, isAxiosError } from "axios";

import { suspensionsApi } from "feedback-api";
import type { Suspension } from "feedback-api";

export type SuspensionRowData = {
  pollId: number;
  professionalName: string | undefined;
  affectedAppointments: number;
  rangeStart: string | undefined;
  rangeEnd: string | undefined;
  centerName: string | undefined;
  effectiveInteractions: number;
  createdAt: string;
  createdBy: string | undefined;
  effectiveInteractionsRatio: number;
};

const useSuspensionsQuery = () => {
  return useQuery<SuspensionRowData[], unknown>({
    queryKey: ["suspensions"],
    queryFn: async ({ signal }) => {
      const {
        data: { suspensions, poll_id },
      } = await suspensionsApi.getSuspensionsV4SuspensionsGet({ signal });
      return suspensions.map((suspension) =>
        transformSuspensionForTableRow(suspension, poll_id),
      );
    },
    retry: (failureCount, error) =>
      failureCount < 3 &&
      !(
        isAxiosError(error) &&
        error.response?.status === HttpStatusCode.Forbidden
      ),
    refetchInterval: 300_000,
  });
};

const transformSuspensionForTableRow = (
  suspension: Suspension,
  pollId: number,
) => {
  return {
    pollId,
    professionalName: suspension.professional_name ?? undefined,
    affectedAppointments: suspension.affected_appointments,
    rangeStart: suspension.range_start ?? undefined,
    rangeEnd: suspension.range_end ?? undefined,
    centerName: suspension.center_name ?? undefined,
    effectiveInteractions: suspension.effective_interactions,
    createdAt: suspension.created_at,
    createdBy: suspension.created_by ?? undefined,
    effectiveInteractionsRatio:
      suspension.effective_interactions / suspension.affected_appointments,
  };
};

export default useSuspensionsQuery;
