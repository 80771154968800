import { InlineIcon } from "@iconify/react";
import { setUser as setSentryUser } from "@sentry/react";
import { useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import { useCallback, useEffect } from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import useAnalytics from "hooks/useAnalytics";
import { limpiaEncuestas } from "store/slices/encuestas";
import { cierraLaSesion } from "store/slices/login";
import { activaModal } from "store/slices/novedades";
import {
  cambiaEsquemaColor,
  ESQUEMA_OSCURO,
  opcionesSelector,
} from "store/slices/opciones";
import { limpiaFiltros, limpiaRespuestas } from "store/slices/respuestas";
import {
  escondeDatosSensibles,
  scramblerSelector,
} from "store/slices/scrambler";
import { setTerm } from "store/slices/search";
import { clearToasts } from "store/slices/toasts";

import "./PopupMenuUsuario.css";

interface PopupMenuUsuarioProps {
  visible: boolean;
  esconder: () => void;
}

const PopupMenuUsuario = ({ visible, esconder }: PopupMenuUsuarioProps) => {
  const { esquema } = useSelector(opcionesSelector);
  const { scrambled } = useSelector(scramblerSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const track = useAnalytics();
  const queryClient = useQueryClient();

  const esc = useCallback(
    (e: KeyboardEvent) => e.key === "Escape" && esconder(),
    [esconder],
  );

  useEffect(() => {
    if (visible) {
      window.addEventListener("keyup", esc);
    } else {
      window.removeEventListener("keyup", esc);
    }
    return () => window.removeEventListener("keyup", esc);
  }, [esc, visible]);

  const container = document.getElementById("popup-menu-usuario");
  if (!container) {
    return null;
  }

  const zoomIn = () => {
    const html = document.documentElement;
    const tamañoAnterior = window
      .getComputedStyle(html, null)
      .getPropertyValue("font-size")
      .slice(0, -2);
    html.style.fontSize = `${Number(tamañoAnterior) + 2}px`;
    track("Feedback", "MenuUsuario", "zoomIn");
  };

  const zoomOut = () => {
    const html = document.documentElement;
    const tamañoAnterior = window
      .getComputedStyle(html, null)
      .getPropertyValue("font-size")
      .slice(0, -2);
    html.style.fontSize = `${Number(tamañoAnterior) - 2}px`;
    track("Feedback", "MenuUsuario", "zoomOut");
  };

  return createPortal(
    <div
      className="PopupMenuUsuario__lamina"
      onClick={esconder}
      style={{ pointerEvents: visible ? "all" : "none" }}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={classNames({
          PopupMenuUsuario: true,
          "PopupMenuUsuario--visible": visible,
        })}
      >
        <div className="PopupMenuUsuario__controles_zoom">
          Ajustar tamaño de letra
          <button className="PopupMenuUsuario__boton_zoom" onClick={zoomOut}>
            <InlineIcon icon="mdi:minus" />
          </button>
          <button className="PopupMenuUsuario__boton_zoom" onClick={zoomIn}>
            <InlineIcon icon="mdi:plus" />
          </button>
        </div>
        <div className="PopupMenuUsuario__opciones">
          <button
            className="PopupMenuUsuario__boton_opcion"
            onClick={(e) => {
              e.stopPropagation();
              dispatch(activaModal());
              esconder();
              track("Feedback", "MenuUsuario", "verNovedades");
            }}
          >
            <InlineIcon
              className="PopupMenuUsuario__icono_opcion"
              icon="mdi:robot-happy-outline"
            />{" "}
            Novedades del servicio
          </button>
          <button
            className="PopupMenuUsuario__boton_opcion"
            onClick={(e) => {
              e.stopPropagation();
              track("Feedback", "MenuUsuario", "cambiarColores", { esquema });
              dispatch(cambiaEsquemaColor());
            }}
          >
            <InlineIcon
              className="PopupMenuUsuario__icono_opcion"
              icon={
                esquema === ESQUEMA_OSCURO
                  ? "mdi:white-balance-sunny"
                  : "mdi:weather-night"
              }
            />{" "}
            Ver colores {esquema === ESQUEMA_OSCURO ? "diurnos" : "nocturnos"}
          </button>
          <button
            className="PopupMenuUsuario__boton_opcion"
            onClick={(e) => {
              e.stopPropagation();
              track("Feedback", "MenuUsuario", "toggleModoIncognito", {
                scrambled,
              });
              dispatch(escondeDatosSensibles(!scrambled));
            }}
          >
            <InlineIcon
              className="PopupMenuUsuario__icono_opcion"
              icon="mdi:incognito"
            />{" "}
            {scrambled ? "Mostrar" : "Ocultar"} datos sensibles
          </button>
          <button
            className="PopupMenuUsuario__boton_opcion"
            onClick={(e) => {
              e.stopPropagation();
              navigate("/tutoriales");
              esconder();
              track("Feedback", "MenuUsuario", "verTutoriales");
            }}
          >
            <InlineIcon
              className="PopupMenuUsuario__icono_opcion"
              icon="mdi:play-box-multiple"
            />{" "}
            Tutoriales de Feedback
          </button>
          <div className="PopupMenuUsuario__separador" />
          <button
            className="PopupMenuUsuario__boton_opcion"
            onClick={() => {
              track("Feedback", "MenuUsuario", "cerrarSesion");
              setSentryUser(null);
              dispatch(limpiaEncuestas());
              dispatch(limpiaFiltros());
              dispatch(limpiaRespuestas());
              dispatch(setTerm(""));
              dispatch(clearToasts());
              dispatch(cierraLaSesion());
              queryClient.clear();
            }}
          >
            <InlineIcon
              className="PopupMenuUsuario__icono_opcion"
              icon="mdi:exit-to-app"
            ></InlineIcon>{" "}
            Cerrar sesión
          </button>
        </div>
      </div>
    </div>,
    container,
  );
};

export default PopupMenuUsuario;
